import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Link,
  Button,
  Divider,
} from '@mui/material';
import {
  Search,
  LocalShipping,
  BubbleChart,
  Label,
  Edit,
  Undo,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';

const suppliesData = [
  { label: 'Tape', quantity: 1, description: 'Used for sealing boxes', default: true, providedBy: [] },
  { label: 'Labels', quantity: 5, description: 'Used for labeling your items', default: true, providedBy: [] },
  { label: 'Permanent Marker', quantity: 1, description: 'Used for writing on labels', default: true, providedBy: [] },
  { label: 'Cardboard Box', quantity: 1, description: 'For storing items', default: false, providedBy: ['Cardboard Box'] },
  { label: 'Bubble Wrap', quantity: 2, description: 'For protecting fragile items', default: false, providedBy: ['Microwave', 'Fridge'] },
  { label: 'Plastic Tote', quantity: 1, description: 'For storing items', default: false, providedBy: ['Plastic Tote'] },
];

const Supplies = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState({});

  const handleToggle = (label) => {
    setOpen((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const filteredSupplies = suppliesData.filter((supply) =>
    supply.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ p: 3, flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Supplies
      </Typography>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box>
          <Typography variant="body1">
            <strong>Supplies Shipping By:</strong> May 3rd, 2025
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Estimated Shipping Time:</strong> 3-5 business days
          </Typography>
          <Typography variant="body1">
            The following supplies will be included in the supply kit that will be shipped to you.
          </Typography>
        </Box>
        <Box sx={{textAlign: 'right'}}>
          <Typography variant="body1" >
            <strong> Need more supplies? {' '}</strong>
          </Typography>
          <Typography variant="body1">
            Many items that you can store will include packing supplies.{' '}
          </Typography>
          <Typography variant="body1">
            <Link href="/items">Add an item</Link>.
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <TextField
        variant="outlined"
        placeholder="Search for a supply..."
        fullWidth
        disabled
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly opaque white background
            zIndex: 2,
          }}
        >
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "text.secondary"}}>
            <LocalShipping sx={{textAlign: 'center', fontSize: "2.5rem", mb: 2}}></LocalShipping>
            <Typography variant="h5" sx={{ color: 'text.secondary', fontWeight: 'bold', textAlign: 'center' }}>
              This storage term is complete.
              <br />
            </Typography>
            <Typography variant="subtitle2" sx={{textAlign: 'center', color: 'text.secondary'}}>
              Supplies ordering form will reopen <strong> April 1st, 2025</strong>.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ filter: 'blur(5px)', pointerEvents: 'none' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Supply</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Provided By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSupplies.map((supply, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ mr: 2 }}>
                            {supply.label === 'Cardboard Box' && <LocalShipping fontSize="large" />}
                            {supply.label === 'Bubble Wrap' && <BubbleChart fontSize="large" />}
                            {supply.label === 'Tape' && <Edit fontSize="large" />}
                            {supply.label === 'Labels' && <Label fontSize="large" />}
                            {supply.label === 'Permanent Marker' && <Undo fontSize="large" />}
                            {supply.label === 'Plastic Tote' && <LocalShipping fontSize="large" />}
                          </Box>
                          <Typography variant="h6">{supply.label}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{supply.description}</TableCell>
                      <TableCell>{supply.quantity}</TableCell>
                      <TableCell>
                        {supply.default ? (
                          'Included by default'
                        ) : (
                          <IconButton size="small" onClick={() => handleToggle(supply.label)}>
                            {open[supply.label] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                    {!supply.default && (
                      <TableRow>
                        <TableCell colSpan={4} sx={{ p: 0 }}>
                          <Collapse in={open[supply.label]} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Typography variant="body2">
                                <strong>Provided by:</strong>{' '}
                                {supply.providedBy.map((item, idx) => (
                                  <span key={idx}>
                                    <Link href="#">{item}</Link>
                                    {idx < supply.providedBy.length - 1 && ', '}
                                  </span>
                                ))}
                              </Typography>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Supplies;

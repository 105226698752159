// src/components/NotificationsCarousel.js
import React from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import CloseIcon from '@mui/icons-material/Close';
import AlarmIcon from '@mui/icons-material/Alarm'; // Importing AlarmIcon

const notifications = [
  {
    id: 1,
    message: 'Your account will be charged on 12/30/2024',
    date: '12/20/2024',
    action: 'VIEW DETAILS',
  },
  {
    id: 2,
    message: 'Supplies pickup deadline is approaching!',
    date: '09/15/2024',
    action: 'SCHEDULE PICKUP',
  },
  // Add more notifications as needed
];

const NotificationsCarousel = () => {
  return (
    <Box sx={{ mb: 0 }}>
      <Carousel sx={{ mb: 0 }}>
        {notifications.map((notification) => (
          <Box
            key={notification.id}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: {xs: "column", md: "row"},
              alignItems: 'center',
              backgroundColor: '#e3f2fd', // Light blue background
              border: '1px solid #3B88EA', // Dark blue border
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#3B88EA', // Dark blue circle background
                borderRadius: '50%',
                width: {md: '40px', xs: '70px'},
                height: {md: '40px', xs: '70px'},
                justifyContent: 'center',
                color: 'white',
                mr: 2,
              }}
            >
              <AlarmIcon sx={{fontSize: {xs: "2rem", md: "unset"}}}/>
            </Box>
            <Box sx={{ flexGrow: 1, my: {xs: 3, md: "unset"} }}>
              <Typography variant="body1">{notification.message}</Typography>
              <Typography variant="body2" color="textSecondary">
                {notification.date}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              sx={{ ml: 2 }}
              onClick={() => alert(`Action for ${notification.message}`)}
            >
              {notification.action}
            </Button>
            <IconButton
              onClick={() => alert(`Dismiss ${notification.message}`)}
              sx={{ ml: 1 }}
            >
            </IconButton>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default NotificationsCarousel;

// src/pages/Inbox.js
import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, Paper } from '@mui/material';

const messages = [
  {
    id: 1,
    sender: 'John Doe',
    subject: 'Important Update',
    date: '2024-06-20',
    content: 'This is an important update regarding your account. Please review it at your earliest convenience.',
  },
  {
    id: 2,
    sender: 'Jane Smith',
    subject: 'Reminder: Meeting Tomorrow',
    date: '2024-06-19',
    content: 'Just a reminder about our meeting scheduled for tomorrow at 10 AM.',
  },
  {
    id: 3,
    sender: 'SummerStore',
    subject: 'Your Order has Shipped',
    date: '2024-06-18',
    content: 'Your recent order has been shipped and is on its way to you.',
  },
];

const Inbox = () => {
  const [selectedMessage, setSelectedMessage] = useState(messages[0]);

  const handleListItemClick = (message) => {
    setSelectedMessage(message);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, display: 'flex' }}>
      <Paper sx={{ width: '30%', mr: 2 }}>
        <Typography variant="h6" sx={{ p: 2 }}>
          Inbox
        </Typography>
        <Divider />
        <List>
          {messages.map((message) => (
            <ListItem
              button
              key={message.id}
              selected={selectedMessage.id === message.id}
              onClick={() => handleListItemClick(message)}
            >
              <ListItemAvatar>
                <Avatar>{message.sender.charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={message.subject}
                secondary={`${message.sender} - ${message.date}`}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Paper sx={{ flex: 1, p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {selectedMessage.subject}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          From: {selectedMessage.sender} - {selectedMessage.date}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1">
          {selectedMessage.content}
        </Typography>
      </Paper>
    </Box>
  );
};

export default Inbox;

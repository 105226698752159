import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, TextField, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stepper, Step, StepLabel, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';

const steps = ['View Cart', 'Shipping & Delivery', 'Confirm'];

const Checkout = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedProducts = location.state?.selectedProducts || [];
  const db = getFirestore();
  const functions = getFunctions();
  const verifyDiscountCode = httpsCallable(functions, 'verifyDiscountCode');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [quantities, setQuantities] = useState({});
  const [paymentOptions, setPaymentOptions] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [showDiscountCode, setShowDiscountCode] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountError, setDiscountError] = useState('');
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [problematicItems, setProblematicItems] = useState([]);

  useEffect(() => {
    if (selectedProducts.length === 0) {
      navigate('/dashboard');
    }
  }, [selectedProducts, navigate]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        if (!productsList) {
          console.log("No products found!");
        }
        setProducts(productsList);

        const initialQuantities = {};
        const initialPaymentOptions = {};
        console.log("Searching products: ", productsList);
        console.log("Searching selected: ", selectedProducts);

        selectedProducts.forEach(product => {
          const productInfo = productsList.find(p => p.id === product.id);

          console.log("Product id: ", product.id);
          console.log("Product Info: ", productInfo);
          initialQuantities[product.id] = productInfo.quantity || 1;
          initialPaymentOptions[product.id] = productInfo.payLater; // Default to pay later if applicable
        });
        setQuantities(initialQuantities);
        setPaymentOptions(initialPaymentOptions);

      } catch (error) {
        console.error('Error fetching products: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();

  }, [db, selectedProducts]);

  const handleQuantityChange = (productId, value) => {
    setQuantities({
      ...quantities,
      [productId]: value,
    });
  };

  const handlePaymentOptionChange = (productId, value) => {
    setPaymentOptions({
      ...paymentOptions,
      [productId]: value,
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCheckout = () => {
    // Handle checkout logic here
    console.log('Quantities:', quantities);
    console.log('Payment options:', paymentOptions);
    console.log('Discount code:', discountCode);
    console.log('Discount amount:', discountAmount);
  };

  const calculateSubtotalDueToday = () => {
    return selectedProducts.reduce((acc, product) => {
      const productInfo = products.find(p => p.id === product.id);
      if (productInfo && quantities[product.id]) {
        return acc + (productInfo.price * quantities[product.id] * (paymentOptions[product.id] ? 0 : 1));
      }
      return acc;
    }, 0);
  };

  const calculateTotalAmount = () => {
    return selectedProducts.reduce((acc, product) => {
      const productInfo = products.find(p => p.id === product.id);
      if (productInfo && quantities[product.id]) {
        return acc + (productInfo.price * quantities[product.id]);
      }
      return acc;
    }, 0);
  };

  const calculateEstimatedTaxes = (subtotal) => {
    return (subtotal - discountAmount) * 0.08; // Assuming 8% tax rate
  };

  const applyDiscountCode = async (code, updatedPaymentOptions) => {
    setIsApplyingDiscount(true);
    try {
      const result = await verifyDiscountCode({ discountCode: code });
      if (result.data.success) {
        if (!result.data.allowPayLater) {
          const itemsWithPayLater = selectedProducts.filter(product => updatedPaymentOptions[product.id]);
          if (itemsWithPayLater.length > 0) {
            setProblematicItems(itemsWithPayLater);
            console.log("Problem items: ", itemsWithPayLater);
            setIsDialogOpen(true);
            setIsApplyingDiscount(false);
            return;
          }
        }
        setDiscountAmount(result.data.total);
        setDiscountError('');
      } else {
        setDiscountAmount(0);
        setDiscountError(result.data.message);
      }
    } catch (error) {
      console.error('Error applying discount code:', error);
      setDiscountAmount(0);
      setDiscountError('An error occurred while applying the discount code');
    } finally {
      setIsApplyingDiscount(false);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    // Change payment options for all problematic items to not pay later
    const updatedPaymentOptions = { ...paymentOptions };
    problematicItems.forEach(item => {
      updatedPaymentOptions[item.id] = false;
    });
  
    setPaymentOptions(updatedPaymentOptions);
  
    // Apply discount with the updated payment options
    applyDiscountCode(discountCode, updatedPaymentOptions);
    setIsDialogOpen(false);
  };
  

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const subtotalDueToday = calculateSubtotalDueToday();
  const totalAmount = calculateTotalAmount();
  const estimatedTaxes = calculateEstimatedTaxes(subtotalDueToday);
  const totalDueToday = Math.max(0, subtotalDueToday + estimatedTaxes - discountAmount);

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Checkout
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{index + 1}. {label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={8}>
          {activeStep === 0 && (
            <Box>
              <TableContainer component={Paper} sx={{ pt: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "2px solid lightgray" }}>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Due Today</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedProducts.map(selectedProduct => {
                      const product = products.find(p => p.id === selectedProduct.id);
                      if (!product) return null;

                      return (
                        <TableRow key={product.id} sx={{ borderTop: 'none' }}>
                          <TableCell sx={{ borderTop: 'none !important' }}>{product.title}</TableCell>
                          <TableCell align="right">
                            ${product.price.toFixed(2)}
                            {paymentOptions[product.id] && (
                              <Typography variant="caption" sx={{ display: 'block', color: 'grey.500' }}>
                                Payment due: 5/11/2025
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {product.maxQuantity ? (
                              product.maxQuantity
                            ) : (
                              <TextField
                                type="number"
                                value={quantities[product.id] || ''}
                                onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                                sx={{ width: '60px' }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            ${paymentOptions[product.id] ? '0.00' : (quantities[product.id] || 1) * product.price.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {activeStep === 1 && (
            <Box>
              <Typography variant="h6">Shipping & Delivery</Typography>
              <TextField label="Address" fullWidth sx={{ my: 2 }} />
              <TextField label="City" fullWidth sx={{ my: 2 }} />
              <TextField label="State" fullWidth sx={{ my: 2 }} />
              <TextField label="ZIP Code" fullWidth sx={{ my: 2 }} />
            </Box>
          )}
          {activeStep === 2 && (
            <Box>
              <Typography variant="h6">Confirm Order</Typography>
              <Typography variant="body1">Review your order details and confirm.</Typography>
              {/* Display summary of order details here */}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2, mt: 1 }}>
              Order Summary
            </Typography>
            <Box sx={{ mb: 2, mt: 1 }}>
              <Typography variant="body1" component="span" sx={{ color: "text.secondary" }}>
                Subtotal:
              </Typography>
              <Typography variant="body1" component="span" sx={{ float: 'right' }}>
                ${subtotalDueToday.toFixed(2)}
              </Typography>
            </Box>
            {discountAmount > 0 && (
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" component="span" sx={{ color: "text.secondary" }}>
                  Discount:
                </Typography>
                <Typography variant="body1" component="span" sx={{ float: 'right' }}>
                  -${discountAmount.toFixed(2)}
                </Typography>
              </Box>
            )}
            {discountAmount > 0 && (
                <Box sx={{ my: 2 }}>
                  <Typography variant="body1" component="span" sx={{ color: "text.secondary" }}>
                    Subtotal after discount:
                  </Typography>
                  <Typography variant="body1" component="span" sx={{ float: 'right' }}>
                    ${(subtotalDueToday - discountAmount).toFixed(2)}
                  </Typography>
                </Box>
                
              )}
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" component="span" sx={{ color: "text.secondary" }}>
                Estimated Taxes:
              </Typography>
              <Typography variant="body1" component="span" sx={{ float: 'right' }}>
                ${estimatedTaxes.toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              <Typography variant="h6" component="span">
                Due Today:
              </Typography>
              <Typography variant="h6" component="span" sx={{ float: 'right' }}>
                ${totalDueToday.toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
              {activeStep < steps.length - 1 ? (
                <Button variant="contained" onClick={handleNext} sx={{ mb: 2 }}>
                  Next
                </Button>
              ) : (
                <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleCheckout}>
                  Place Order
                </Button>
              )}
              {activeStep > 0 && (
                <Button variant="outlined" onClick={handleBack} sx={{ mb: 2 }}>
                  Back
                </Button>
              )}
            </Box>
            <Box sx={{ my: 2 }}>
              {!showDiscountCode && (
                <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1" component="span" sx={{ color: "text.secondary" }}>
                    Discount Code
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => setShowDiscountCode(true)}
                    sx={{ textTransform: 'none' }}
                  >
                    <Typography variant="body1" sx={{ color: "primary.main" }}>Add</Typography>
                  </Button>
                </Box>
              )}
              {showDiscountCode && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <TextField
                    label="Discount Code"
                    fullWidth
                    size="small"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    error={!!discountError}
                    helperText={discountError}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => applyDiscountCode(discountCode, paymentOptions)}
                    sx={{ textTransform: 'none', ml: 1 }}
                    disabled={isApplyingDiscount}
                  >
                    {isApplyingDiscount ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Typography variant="body1" sx={{ color: "primary.main" }}>Apply</Typography>
                    )}
                  </Button>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Payment Option Adjustment Required</DialogTitle>
        <DialogContent>
          <Typography>
            The discount code you entered cannot be applied to items with the "Pay Later" option. The following items will need to be paid now:
          </Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {problematicItems.map(item => {
                  const product = products.find(p => p.id === item.id);
                  if (!product) return null;

                  return (
                    <TableRow key={item.id}>
                      <TableCell>{product.title}</TableCell>
                      <TableCell align="right">${product.price}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleDialogConfirm} color="primary" variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Checkout;

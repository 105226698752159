import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Tabs, Tab, Button } from '@mui/material';
import GeneralInformation from '../components/UniversityGeneralInformation';
import AmbassadorInformation from '../components/UniversityAmbassadorInformation';
import ContainerLocationEditor from '../components/ContainerLocationEditor';
import UniversityAppointments from '../components/UniversityAppointments'; // Import the new component
import { db } from '../firebase'; // Import the Firebase setup
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';

const initialAmbassadorInfo = {
  name: 'John Doe',
  contact: 'johndoe@example.com',
};

const UniversityInfo = () => {
  const navigate = useNavigate();
  const { universityID } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [generalInfo, setGeneralInfo] = useState({});
  const [containerLocations, setContainerLocations] = useState([]);
  const [appointments, setAppointments] = useState([]); // State for appointments
  const [ambassadorInfo, setAmbassadorInfo] = useState(initialAmbassadorInfo); // Ambassador info state

  useEffect(() => {
    const fetchUniversityData = async () => {
      // Fetch general information
      const generalDocRef = doc(db, `universities/${universityID}`);
      const generalDocSnap = await getDoc(generalDocRef);
      console.log("Docsnap: ", generalDocSnap);
      if (generalDocSnap.exists()) {
        setGeneralInfo(generalDocSnap.data());
      }

      // Fetch container locations
      const containerCollectionRef = collection(db, `universities/${universityID}/containerLocations`);
      const containerSnapshot = await getDocs(containerCollectionRef);
      const containerList = containerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("Container Locations:", containerList);
      setContainerLocations(containerList);
    };

    fetchUniversityData();
  }, [universityID]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>

          <Button variant="outlined" onClick={() => navigate(-1)} sx={{width: "auto", mb: 2}}>
            Back
          </Button>
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>

         <Typography variant="h4" sx={{alignContent: "flex-end"}}>
            University Settings
          </Typography>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Typography variant="subtitle2" sx={{textAlign: "right"}}>
            Selected:
          </Typography>
          <Typography variant="h5" gutterBottom>
            {generalInfo.name}
          </Typography>
        </Box>
      </Box>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="General Information" />
          <Tab label="Ambassador Information" />
          <Tab label="Container Locations" />
          <Tab label="Appointments" /> {/* New tab */}
        </Tabs>
      </Paper>
      <Paper sx={{ p: 3, mt: 2 }}>
        {selectedTab === 0 && (
          <GeneralInformation generalInfo={generalInfo} setGeneralInfo={setGeneralInfo} />
        )}
        {selectedTab === 1 && (
          <AmbassadorInformation ambassadorInfo={ambassadorInfo} setAmbassadorInfo={setAmbassadorInfo} />
        )}
        {selectedTab === 2 && (
          <ContainerLocationEditor
            universityID={universityID}
            containerLocations={containerLocations}
            setContainerLocations={setContainerLocations}
          />
        )}
        {selectedTab === 3 && (
          <UniversityAppointments
            appointments={appointments}
            setAppointments={setAppointments}
            containerLocations={containerLocations}
          />
        )}
      </Paper>
    </Box>
  );
};

export default UniversityInfo;

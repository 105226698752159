import React, { useMemo } from 'react';
import { Edges, Text } from '@react-three/drei';
import * as THREE from 'three';

const PhysicsObject = ({ position, dimensions, color, onPointerDown, itemData }) => {
  const { itemType, itemName } = itemData;
  const labelText = `${itemType}\n"${itemName}"`;
  const fontSize = Math.min(dimensions[0], dimensions[1], dimensions[2]) * 0.12; // Adjust font size relative to the smallest dimension
  const textOffset = 0.01; // Small offset to avoid z-fighting

  return (
    <group position={position} onPointerDown={(e) => onPointerDown(e, itemData)}>
      <mesh>
        <boxGeometry args={dimensions} />
        <meshStandardMaterial color={color} />
        <Edges scale={1.0} color="black" linewidth={1}>
          <lineBasicMaterial color="black" linewidth={2} attach="material" />
        </Edges>
      </mesh>

     
    </group>
  );
};

export default PhysicsObject;

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei';

const createAGrid = (opts) => {
  const { width, length, linesWidth, linesHeight, color, orientation } = opts;

  const material = new THREE.LineBasicMaterial({
    color: color,
    opacity: 0.2
  });

  const gridObject = new THREE.Object3D();
  const vertices = [];
  const stepw = 2 * width / linesWidth;
  const steph = 2 * length / linesHeight;

  for (let i = -width; i <= width; i += stepw) {
    if (orientation === 'xy') {
      vertices.push(-length, i, 0, length, i, 0);
    } else if (orientation === 'xz') {
      vertices.push(-length, 0, i, length, 0, i);
    } else if (orientation === 'yz') {
      vertices.push(0, i, -length, 0, i, length);
    }
  }

  for (let i = -length; i <= length; i += steph) {
    if (orientation === 'xy') {
      vertices.push(i, -width, 0, i, width, 0);
    } else if (orientation === 'xz') {
      vertices.push(i, 0, -width, i, 0, width);
    } else if (orientation === 'yz') {
      vertices.push(0, -width, i, 0, width, i);
    }
  }

  const gridGeo = new THREE.BufferGeometry();
  gridGeo.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
  const line = new THREE.LineSegments(gridGeo, material);
  gridObject.add(line);

  return gridObject;
};

const Grid = ({ gridLength, gridWidth, scale, orientation }) => {
  const gridRef = useRef();
  const { scene } = useThree();

  useEffect(() => {
    let newLength = (gridLength / scale) / 2;
    let newWidth = (gridWidth / scale) / 2;

    const grid = createAGrid({
      length: newLength,
      width: newWidth,
      linesHeight: gridLength / 7,
      linesWidth: gridWidth / 7,
      color: 0x000000, // Dark gray color
      orientation: orientation
    });

    if (orientation === 'xy') {
      grid.position.set(newLength, newWidth, 0);
    } else if (orientation === 'xz') {
      grid.position.set(newLength, 0, newWidth);
    } else if (orientation === 'yz') {
      grid.position.set(0, newWidth, newLength);
    }

    scene.add(grid);

    const circleGeometry = new THREE.CircleGeometry(0.5, 32);
    const circleMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
    const circle = new THREE.Mesh(circleGeometry, circleMaterial);
    if (orientation === 'xy') {
      circle.rotation.z = -Math.PI / 2;
    } else if (orientation === 'xz') {
      circle.rotation.x = -Math.PI / 2;
    } else if (orientation === 'yz') {
      circle.rotation.y = -Math.PI / 2;
    }
    scene.add(circle);

    return () => {
      scene.remove(grid);
      scene.remove(circle);
    };
  }, [scene, gridLength, gridWidth, scale, orientation]);

  return (
    <group ref={gridRef}>
      <Text
        position={orientation === 'xy' ? [(gridWidth / scale) / 2, (gridLength / scale) + 10, 0] :
                  orientation === 'xz' ? [(gridWidth / scale) / 2, 0, (gridLength / scale) + 10] :
                                         [0, (gridWidth / scale) / 2, (gridLength / scale) + 10]}
        rotation={orientation === 'xy' ? [0, 0, 0] :
                   orientation === 'xz' ? [-Math.PI / 2, 0, 0] :
                                          [0, -Math.PI / 2, 0]}
        fontSize={1}
        color="black"
        anchorX="center" // Center the text horizontally
        anchorY="middle" // Center the text vertically
      >
        Front
      </Text>
    </group>
  );
};

export default Grid;

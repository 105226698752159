import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Button, Tab, Tabs, CircularProgress } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import PriceComparison from '../components/PriceComparison';
import { useNotification } from '../contexts/NotificationContext';

const plansVisual = {
  '0001': {
    title: 'Starter',
    subtitle: 'Up to 10 items, any type.',
    price: '$200',
    registrationFee: '+ $50 Reservation Fee',
    perItemPrice: '$20',
    image: '../images/Plan1-01.webp', // replace with actual image path
    features: [
      '4 months of storage for up to 10 items',
      'Need more? Add items for $25 each.',
      'Packing supplies',
      '$200 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $25/hr',
    ],
  },
  '0002': {
    title: 'Basic',
    subtitle: 'Up to 20 items, any type.',
    price: '$400',
    registrationFee: '+ $50 Reservation Fee',
    perItemPrice: '$20',
    image: '../images/Plan2-01.webp', // replace with actual image path
    features: [
      '4 months of storage for up to 20 items',
      'Need more? Add items for $25 each.',
      'Packing supplies',
      '$200 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $25/hr',
    ],
  },
  '0003': {
    title: 'Deluxe',
    subtitle: 'Up to 30 items, any type.',
    price: '$600',
    registrationFee: '+ $50 Reservation Fee',
    perItemPrice: '$20',
    image: '../images/Plan3-01.webp', // replace with actual image path
    features: [
      '4 months of storage for up to 30 items',
      'Need more? Add items for $25 each.',
      'Packing supplies',
      '$200 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $25/hr',
    ],
  },
  '0004': {
    title: 'Per Item',
    subtitle: 'Pay for each item individually.',
    price: '$30',
    registrationFee: '+ $50 Reservation Fee',
    image: '../images/Plan4-01.webp', // replace with actual image path
    features: [
      '4 months of storage per item',
      'Packing supplies',
      'Optional item insurance',
      'Any sized items. Terms apply.',
      'Student movers for $25/hr',
    ],
  },
};

const StoragePlanPrivate = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const db = getFirestore();
  const { addNotification } = useNotification();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoading(true); // Ensure loading state is set before fetching
        const productIds = ['0001', '0002', '0003', '0004'];
        const productsList = [];

        for (const productId of productIds) {
          const productDoc = await getDoc(doc(db, 'products', productId));
          if (productDoc.exists()) {
            productsList.push({ id: productId, ...productDoc.data() });
          }
        }

        setPlans(productsList);
      } catch (error) {
        console.error('Error fetching plans: ', error);
      } finally {
        setLoading(false); // End loading state after fetching
      }
    };

    fetchPlans();
  }, [db]);

  useEffect(() => {
    const fetchSelectedPlan = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true); // Ensure loading state is set before fetching
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setSelectedPlan(userData.storagePlan || '');
        }
      } catch (error) {
        console.error('Error fetching selected plan: ', error);
      } finally {
        setLoading(false); // End loading state after fetching
      }
    };

    fetchSelectedPlan();
  }, [currentUser, db]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handlePlanSelect = (planId) => {
    addNotification({
      status: 'error',
      message: 'The storage term has ended. Storage plan registration for the next term will open on April 1st, 2025.',
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Tabs centered value={tabIndex} onChange={handleTabChange} aria-label="Storage Plan Tabs">
        <Tab label="Available Plans" />
        <Tab label="Price Comparison" />
      </Tabs>
      {tabIndex === 0 && (
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center"}}>
          <Grid container spacing={3} maxWidth="xl" sx={{ mt: 3 }}>
            {plans.map((plan) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={plan.id}>
                <Paper
                  elevation={3}
                  sx={{
                    mx: { xs: 2, md: 0 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 2,
                    borderColor: 'primary.main',
                    borderWidth: selectedPlan === plan.id ? 2 : 1,
                    backgroundColor: 'none',
                    borderStyle: 'solid',
                    position: 'relative',
                    height: '100%',
                    overflow: "hidden",
                  }}
                > 
                  
                  <Box sx={{
                    backgroundColor: plan.id === "0004" ? "none" : "primary.main",
                    width: "100%", pt: 3, 
                    color: plan.id === "0004" ? "unset" : "white", 
                    backgroundImage: 'url(/images/background_pattern.webp)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',}}>
                    
                    <Typography variant="h5" sx={{ fontWeight: '1000' }}>
                      {plansVisual[plan.id].title}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ 
                      color: plan.id === "0004" ? 'gray' : 'lightgray', 
                      mb: 1 }}>
                      {plansVisual[plan.id].subtitle}
                    </Typography>
                    <Box component="img" src={plansVisual[plan.id].image} alt={plansVisual[plan.id].title} sx={{ height: 150, mb: 2 }} />
                  </Box>
                
                  <Box sx={{p: 2, px: 3, width: "100%"}}>
                    <Box sx={{ width: '100%', textAlign: 'left', mb: 3 }}>
                      <Box sx={{display: "flex", alignItems: "flex-end"}}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                          {plansVisual[plan.id].price}
                        </Typography>

                        <Typography variant="h6" sx={{ 
                          display: plan.id === "0004" ? "block" : "none",
                          pl: 1, 
                          color: 'text.secondary'
                           }}>
                          / item
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {plansVisual[plan.id].registrationFee}
                      </Typography>
                    </Box>
                    <Button
                      variant={selectedPlan === plan.id ? 'outlined' : 'contained'}
                      color="secondary"
                      fullWidth
                      sx={{
                        mt: 'auto',
                        mb: 3,
                        p: 1.5,
                        fontWeight: 'bold',
                      }}
                      onClick={() => handlePlanSelect(plan.id)}
                    >
                      {selectedPlan === plan.id ? 'Selected' : 'Get Started'}
                    </Button>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Typography variant="body2" sx={{ mb: 2, alignText: 'left', color: 'text.secondary' }}>
                        <strong>Includes all of the following:</strong>
                      </Typography>
                      {plansVisual[plan.id].features.map((feature, idx) => (
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: 'text.secondary' }} key={idx}>
                          <Check sx={{ height: '75%', pr: 1 }} />
                          <Typography variant="body2" sx={{ mb: 2, alignText: 'left' }}>
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  

                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h4" sx={{ mb: 2, fontFamily: 'Work Sans', color: 'primary.main', mt: 2 }}>
            If you store <strong>10 items </strong> with...
          </Typography>
          <PriceComparison />
        </Box>
      )}
    </Box>
  );
};

export default StoragePlanPrivate;

import React, { useState } from 'react';
import { Box, Typography, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const GeneralInformation = ({ generalInfo, setGeneralInfo }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentGeneralInfo, setCurrentGeneralInfo] = useState(generalInfo);

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleSave = () => {
    setGeneralInfo(currentGeneralInfo);
    handleDialogClose();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          General Information
        </Typography>
        <Button variant="contained" color="primary" onClick={handleEditClick}>
          Edit
        </Button>
      </Box>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="body1">University Name: {generalInfo.name}</Typography>
        <Typography variant="body1">Location: {generalInfo.location}</Typography>
        <Typography variant="body1">Established: {generalInfo.established}</Typography>
      </Paper>

      <Dialog open={editDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit General Information</DialogTitle>
        <DialogContent>
          <Box component="form">
            <TextField
              label="University Name"
              fullWidth
              margin="normal"
              value={currentGeneralInfo.universityName}
              onChange={(e) => setCurrentGeneralInfo({ ...currentGeneralInfo, universityName: e.target.value })}
            />
            <TextField
              label="Location"
              fullWidth
              margin="normal"
              value={currentGeneralInfo.location}
              onChange={(e) => setCurrentGeneralInfo({ ...currentGeneralInfo, location: e.target.value })}
            />
            <TextField
              label="Established"
              fullWidth
              margin="normal"
              value={currentGeneralInfo.established}
              onChange={(e) => setCurrentGeneralInfo({ ...currentGeneralInfo, established: e.target.value })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GeneralInformation;

import React, { useState } from 'react';
import { Box, Button, IconButton, Collapse, List, ListItem, ListItemButton, ListItemText, Divider, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const NavBar = () => {
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutAnchorEl, setAboutAnchorEl] = useState(null);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const getLinkStyle = (path) => ({
    color: 'white',
    mx: 1.5,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      transform: location.pathname === path ? 'scaleX(1)' : 'scaleX(0)',
      height: '2px',
      bottom: '-2px',
      left: '25%',
      backgroundColor: 'white',
      transformOrigin: 'bottom right',
      transition: 'transform 0.25s ease-out',
    },
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleAboutClick = (event) => {
    setAboutAnchorEl(event.currentTarget);
  };

  const handleAboutClose = () => {
    setAboutAnchorEl(null);
  };

  const toggleMobileDropdown = () => {
    setMobileDropdownOpen(!mobileDropdownOpen);
  };

  return (
    <Box sx={{ zIndex: 999, position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 4, py: 3, color: 'white', backgroundColor: location.pathname === '/' ? { xs: 'primary.main', md: 'transparent' } : 'primary.main' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/">
          <Box
            component="img"
            src='/images/SummerStore-Logo.webp'
            alt="SummerStore Logo"
            sx={{
              height: '20px', // Adjust the height as needed
              width: 'auto', // Maintain aspect ratio
              pl: 1,
            }}
          />
        </Link>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Button component={Link} to="/" sx={getLinkStyle('/')}>Home</Button>
        <Button component={Link} to="/pricing" sx={getLinkStyle('/pricing')}>Pricing</Button>
        <Button
          sx={getLinkStyle('/about-us')}
          onClick={handleAboutClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          About
        </Button>
        <Menu
          anchorEl={aboutAnchorEl}
          open={Boolean(aboutAnchorEl)}
          onClose={handleAboutClose}
          sx={{
            mt: "1px",
            "& .MuiMenu-paper": {
              boxShadow: "none",
              backgroundColor: "primary.main",
              color: "white"
            },
          }}
        >
        <MenuItem sx={{ px: 3, fontSize: "0.875rem" }} component={Link} to="/storable-items" onClick={handleAboutClose}>STORABLE ITEMS</MenuItem>
          <MenuItem sx={{ px: 3, fontSize: "0.875rem" }} component={Link} to="/about-us" onClick={handleAboutClose}>ABOUT US</MenuItem>
        </Menu>
        {currentUser ? (
          <>
            <Button component={Link} to="/dashboard" variant="contained" color="secondary" sx={{ mx: 1.5 }}>Dashboard</Button>
            <Button onClick={handleLogout} variant="outlined" color="secondary" sx={{ mx: 1.5 }}>Log Out</Button>
          </>
        ) : (
          <>
            <Button component={Link} to="/registration" variant="contained" color="secondary" sx={{ mx: 1.5 }}><strong>Sign Up</strong></Button>
            <Button component={Link} to="/login" variant="outlined" color="secondary" sx={{ mx: 1.5, color: 'white' }}><strong>Login</strong></Button>
          </>
        )}
      </Box>
      <IconButton sx={{ display: { xs: 'block', md: 'none' } }} onClick={toggleMenu}>
        <MenuIcon sx={{ color: 'white' }} />
      </IconButton>
      <Collapse in={menuOpen} timeout="auto" unmountOnExit sx={{ zIndex: '999', width: '100%', backgroundColor: 'primary.main', color: 'white', position: 'absolute', top: '100%', left: 0 }}>
        <List>
          <ListItem>
            <ListItemButton component={Link} to="/" sx={{ width: '100%', textAlign: 'center' }}>
              <ListItemText primary="Home" />
            </ListItemButton>
            <Divider />
          </ListItem>
          <ListItem>
            <ListItemButton component={Link} to="/pricing" sx={{ width: '100%', textAlign: 'center' }}>
              <ListItemText primary="Pricing" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={toggleMobileDropdown} sx={{ width: '100%', textAlign: 'center', position: 'relative' }}>
              <ListItemText primary="About" />
              <Box sx={{ position: 'absolute', right: 20, top: '50%', transform: 'translateY(-50%)' }}>
                {mobileDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Box>
            </ListItemButton>
          </ListItem>
          <Collapse in={mobileDropdownOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem >
                <ListItemButton component={Link} to="/storable-items" sx={{ width: '100%', textAlign: 'center' }}>
                  <ListItemText primary="Storable Items" />
                </ListItemButton>
              </ListItem>
              <ListItem >
                <ListItemButton component={Link} to="/about-us" sx={{ width: '100%', textAlign: 'center' }}>
                  <ListItemText primary="About Us" />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          {currentUser ? (
            <>
              <ListItem>
                <ListItemButton component={Link} to="/dashboard" variant="contained" color="secondary" sx={{ width: '100%', textAlign: 'center' }}>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={handleLogout} variant="outlined" color="secondary" sx={{ width: '100%', textAlign: 'center', color: 'white' }}>
                  <ListItemText primary="Log Out" />
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem>
                <Button component={Link} to="/registration" variant="contained" color="secondary" sx={{ width: '100%', textAlign: 'center' }}>
                  <ListItemText primary="Sign Up" sx={{ fontWeight: "bold" }} />
                </Button>
              </ListItem>
              <ListItem>
                <Button component={Link} to="/login" variant="outlined" color="secondary" sx={{ width: '100%', textAlign: 'center', color: 'white' }}>
                  <ListItemText primary="Login" />
                </Button>
              </ListItem>
            </>
          )}
        </List>
      </Collapse>
    </Box>
  );
};

export default NavBar;

import React, { useState } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import MyItems from './pages/MyItems';
import Supplies from './pages/Supplies';
import StoragePlanPrivate from './pages/StoragePlanPrivate';
import SupportTicketForm from './pages/SupportTicketForm';
import Settings from './pages/AccountSettings';
import Appointments from './pages/Appointments';
import AddItem from './pages/AddItem';
import Registration from './pages/Registration';
import ItemInfo from './pages/ItemInfo';
import Support from './pages/Support';
import EditItem from './pages/EditItem';
import Inbox from './pages/Inbox';
import Login from './pages/Login';
import StorableItems from './pages/StorableItems';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Pricing from './pages/Pricing';
import UniversityInfo from './pages/UniversityInfo';  
import Universities from './pages/UniversitySettings'; 
import AdminDashboard from './pages/AdminDashboard';
import SystemMonitoring from './pages/SystemMonitoring';
import SalesDashboard from './pages/SalesDashboard';
import SalesDetail from './pages/SalesDetail';
import UserDashboard from './pages/UserDashboard';
import UserDetail from './pages/UserDetail';
import ContainerViewerPage from './pages/ContainerViewerPage';
import UniversityContainersView from './pages/UniversityContainersView';
import UniversityContainersSelection from './pages/UniversityContainersSelection';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Checkout from './pages/Checkout';
import { NotificationProvider } from './contexts/NotificationContext'; // Import the NotificationProvider
import { useAuth } from './contexts/AuthContext';

import './App.css';
import ContainerViewer from './components/ContainerViewer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#134F75',
    },
    secondary: {
      main: '#007bff',
      alternate: '#072a40',
    },
    background: {
      default: '#f8f9fa',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  function DashboardRoute() {

    const { userClaims } = useAuth();

    return (
      <PrivateRoute>
        <Layout
          theme={theme}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          breadcrumb={userClaims.role === 'admin' ? 'Admin Dashboard' : 'Customer Dashboard'}
        >
          <Routes>
            {userClaims.role === 'admin' ? (
              <>
                <Route path="" element={<AdminDashboard />} />
                <Route path="universities" element={<Universities />} />
                <Route path="universities/:universityID" element={<UniversityInfo />} />
                <Route path="system-monitoring" element={<SystemMonitoring />} />

                <Route path="containers" element={<UniversityContainersSelection />} />
                <Route path="containers/university/:universityID" element={<UniversityContainersView />} />
                <Route path="containers/container/:containerID" element={<ContainerViewerPage />} />
 
                <Route path="sales-dashboard" element={<SalesDashboard />} />
                <Route path="sales-dashboard/:transactionId" element={<SalesDetail />} />
                <Route path="users" element={<UserDashboard />} />
                <Route path="users/:userId" element={<UserDetail />} />
              </>
            ) : (
              <Route path="" element={<Dashboard />} />
            )}
            <Route path="items" element={<MyItems />} />
            <Route path="supplies" element={<Supplies />} />
            <Route path="plan" element={<StoragePlanPrivate />} />
            <Route path="add-item" element={<AddItem />} />
            <Route path="settings" element={<Settings />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="support" element={<Support />} />
            <Route path="support/create-ticket" element={<SupportTicketForm />} />
            <Route path="inbox" element={<Inbox />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="container-viewer" element={<Dashboard />} />
            <Route path="edit-item/:itemId" element={<EditItem />} />
            <Route path="item-info/:itemId" element={<ItemInfo />} />
          </Routes>
        </Layout>
      </PrivateRoute>
    );
  }

  return (
    <Router>
      <AuthProvider>
        <NotificationProvider> {/* Wrap the app in NotificationProvider */}
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/storable-items" element={<StorableItems />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/dashboard/*" element={<DashboardRoute />} />
            </Routes>
          </ThemeProvider>
        </NotificationProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Container, TextField, Button, Typography, Paper, Box, InputLabel, Input } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // Import CloudUploadIcon
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const SupportTicketForm = ({ firstName }) => {
  const { handleSubmit, control, reset } = useForm();
  const [images, setImages] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const navigate = useNavigate();

  const onSubmit = (data) => {
    // Handle form submission, e.g., send data to the server
    console.log('Form data:', data);
    console.log('Uploaded images:', images);
    console.log('Editor content:', editorContent);

    // Show confirmation message and reset form
    setSubmitted(true);
    reset();
    setImages([]);
    setEditorContent('');
  };

  const handleImageUpload = (event) => {
    const uploadedImages = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...uploadedImages]);
  };

  const handleGoBack = () => {
    setSubmitted(false);
    navigate('/dashboard/support');
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {submitted ? (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <Typography variant="h5" sx={{ mb: 3 }}>
            Support ticket submitted successfully!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBack}
          >
            Go Back
          </Button>
        </Box>
      ) : (
        <>
        <Button variant="outlined"
            onClick={handleGoBack} sx={{mb: 4}}>Back</Button>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Create Support Ticket
          </Typography>
          <Paper sx={{p: 3}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h6">
              Subject
            </Typography>
            <Controller
              name="subject"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter Subject"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
            <Typography variant="h6" sx={{ mt: 3 }}>
              How can we help you?
            </Typography>
            <Box sx={{ mt: 2, mb: 2 }}>
              <ReactQuill
                value={editorContent}
                onChange={setEditorContent}
                theme="snow"
                placeholder="Explain the problem..."
                sx={{bgcolor: "white"}}
              />
            </Box>
            <InputLabel htmlFor="upload-images" sx={{ mt: 3 }}>Upload Files</InputLabel>
            <Box sx={{ mt: 1, mb: 2 }}>
              <Input
                id="upload-images"
                type="file"
                multiple
                onChange={handleImageUpload}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload
              </Button>
              <Box>
                {images.map((image, index) => (
                  <Typography key={index} variant="body2">
                    {image.name}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
            >
              Submit Ticket
            </Button>
          </form>

          </Paper>
        </>
      )}
    </Container>
  );
};

export default SupportTicketForm;

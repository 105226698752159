import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, Button, Tab, Tabs } from '@mui/material';
import { Check } from '@mui/icons-material';
import PriceComparison from '../components/PriceComparison';

const plans = [
  {
    title: 'Starter',
    subtitle: 'Up to 10 items, $10/item.',
    price: '$200',
    registrationFee: '+ $50 Registration Fee',
    perItemPrice: '$20',
    image: 'images/Plan1-01.webp', // replace with actual image path
    features: [
      '4 months of storage for up to 10 items',
      'Need more? Add items for $25 each.',
      'Packing supplies',
      '$200 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $25/hr',
    ],
  },
  {
    title: 'Basic',
    subtitle: 'Up to 20 items, any type.',
    price: '$400',
    registrationFee: '+ $50 Registration Fee',
    perItemPrice: '$20',
    image: 'images/Plan2-01.webp', // replace with actual image path
    features: [
      '4 months of storage for up to 20 items',
      'Need more? Add items for $25 each.',
      'Packing supplies',
      '$200 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $25/hr',
    ],
  },
  {
    title: 'Deluxe',
    subtitle: 'Up to 30 items, any type.',
    price: '$600',
    registrationFee: '+ $50 Registration Fee',
    perItemPrice: '$20',
    image: 'images/Plan3-01.webp', // replace with actual image path
    features: [
      '4 months of storage for up to 30 items',
      'Need more? Add items for $25 each.',
      'Packing supplies',
      '$200 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $25/hr',
    ],
  },
  {
    title: 'Per Item',
    subtitle: 'Pay for each item individually.',
    price: '$30',
    registrationFee: '+ $50 Registration Fee',
    image: 'images/Plan4-01.webp', // replace with actual image path
    features: [
      '4 months of storage per item',
      'Packing supplies',
      'Optional item insurance',
      'Any sized items. Terms apply.',
      'Student movers for $25/hr',
    ],
  },
];

const StoragePlan = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Box sx={{  p: 2 }}>
      <Tabs centered value={tabIndex} onChange={handleTabChange} aria-label="Storage Plan Tabs">
        <Tab label="Available Plans" />
        <Tab label="Price Comparison" />
      </Tabs>
      {tabIndex === 0 && (
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center"}}>
          <Grid container spacing={3} maxWidth="xl" sx={{ mt: 3 }}>
            {plans.map((plan, index) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    mx: { xs: 2, md: 0 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 2,
                    borderColor: 'primary.main',
                    borderWidth: 1,
                    backgroundColor: 'none',
                    borderStyle: 'solid',
                    position: 'relative',
                    height: '100%',
                    overflow: "hidden",
                  }}
                > 
                  
                  <Box sx={{
                    backgroundColor: plan.title === "Per Item" ? "none" : "primary.main",
                    width: "100%", pt: 3, 
                    color: plan.title === "Per Item" ? "unset" : "white", 
                    backgroundImage: 'url(/images/background_pattern.webp)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',}}>
                    
                    <Typography variant="h5" sx={{ fontWeight: '1000' }}>
                      {plan.title}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ 
                      color: plan.title === "Per Item" ? 'gray' : 'lightgray', 
                      mb: 1 }}>
                      {plan.subtitle}
                    </Typography>
                    <Box component="img" src={plan.image} alt={plan.title} sx={{ height: 150, mb: 2 }} />
                  </Box>
                
                  <Box sx={{p: 2, px: 3, width: "100%"}}>
                    <Box sx={{ width: '100%', textAlign: 'left', mb: 3 }}>
                      <Box sx={{display: "flex", alignItems: "flex-end"}}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                          {plan.price}
                        </Typography>

                        <Typography variant="h6" sx={{ 
                          display: plan.title === "Per Item" ? "block" : "none",
                          pl: 1, 
                          color: 'text.secondary'
                           }}>
                          / item
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {plan.registrationFee}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      sx={{
                        mt: 'auto',
                        mb: 3,
                        p: 1.5,
                        fontWeight: 'bold',
                      }}
                    >
                      Get Started
                    </Button>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Typography variant="body2" sx={{ mb: 2, alignText: 'left', color: 'text.secondary' }}>
                        <strong>Includes all of the following:</strong>
                      </Typography>
                      {plan.features.map((feature, idx) => (
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: 'text.secondary' }} key={idx}>
                          <Check sx={{ height: '75%', pr: 1 }} />
                          <Typography variant="body2" sx={{ mb: 2, alignText: 'left' }}>
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  

                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h4" sx={{ mb: 2, fontFamily: 'Work Sans', color: 'primary.main', mt: 2 }}>
            If you store <strong>10 items </strong> with...
          </Typography>
          <PriceComparison />
        </Box>
      )}
    </Box>
  );
};

export default StoragePlan;

// src/components/Overlay.js
import React from 'react';
import { Box } from '@mui/material';

const Overlay = ({ open, onClick }) => {
  return (
    open && (
      <Box
        onClick={onClick}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1,
          opacity: open ? 1 : 0,
          transition: 'opacity 0.5s ease',
        }}
      />
    )
  );
};

export default Overlay;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Dialog, Typography, Paper, TextField, DialogTitle, DialogContent, DialogActions, Button, Grid, IconButton, Avatar, Tabs, Tab } from '@mui/material';
import { AddAPhoto, DeleteOutline } from '@mui/icons-material';
import { getFirestore, updateDoc, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { useNotification } from '../contexts/NotificationContext'; // Import the notification hook

const EditItem = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { addNotification } = useNotification(); // Destructure addNotification from useNotification
  const db = getFirestore();
  const [allConfigs, setAllConfigs] = useState({});
  const [itemConfig, setItemConfig] = useState(null);
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemContents, setItemContents] = useState('');
  const [itemImage, setItemImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [originalItem, setOriginalItem] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const loadAllConfigs = async () => {
    try {
      const itemConfigsModule = await import('../config/items.json');
      const itemConfigs = itemConfigsModule.default || itemConfigsModule;

      if (!Array.isArray(itemConfigs)) {
        throw new TypeError('itemConfigs is not an array');
      }

      const items = {};
      itemConfigs.forEach((itemConfig) => {
        items[itemConfig.id] = itemConfig;
      });

      return items;
    } catch (error) {
      console.error('Error loading item configurations:', error);
      return {};
    }
  };

  useEffect(() => {
    const fetchConfigs = async () => {
      const configs = await loadAllConfigs();
      setAllConfigs(configs);
    };

    fetchConfigs();
  }, []);

  useEffect(() => {
    const fetchItemData = async () => {
      if (currentUser && itemId) {
        try {
          const itemDoc = await getDoc(doc(db, `inventoryUsers/${currentUser.uid}/items`, itemId));
          if (itemDoc.exists()) {
            const itemData = itemDoc.data();
            const itemType = itemData.type;
            setItemConfig(allConfigs[itemType]);
            setItemName(itemData.name || '');
            setItemDescription(itemData.description || '');
            if (itemData.contents) {
              setItemContents(itemData.contents || '');
            }
            setOriginalItem(itemData);
          }
        } catch (error) {
          console.error('Error fetching item data:', error);
        }
      }
    };

    fetchItemData();
  }, [currentUser, itemId, allConfigs]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setItemImage(file);
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setAnchorEl(null);
  };

  const handleDeleteItem = async () => {
   /*  try {
      await deleteDoc(doc(db, `inventoryUsers/${currentUser.uid}/items`, itemId));
      addNotification({ status: 'success', message: 'Item deleted successfully!' });
      navigate('/dashboard/items');
    } catch (error) {
      console.error('Error deleting item: ', error);
      addNotification({ status: 'error', message: 'Error deleting item.' });
    } */
    addNotification({
      status: 'error',
      message: 'The storage term has ended. Deleting items is no longer permitted.',
    });
    handleDialogClose();
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSave = async () => {
   /*  if (currentUser && itemId) {
      if (
        originalItem.name === itemName &&
        originalItem.description === itemDescription &&
        originalItem.contents === itemContents &&
        !itemImage
      ) {
        addNotification({ status: 'warning', message: 'No changes were made.' });
        return;
      }

      try {
        const itemRef = doc(db, `inventoryUsers/${currentUser.uid}/items`, itemId);
        await updateDoc(itemRef, {
          name: itemName,
          description: itemDescription,
          contents: itemContents,
        });

        addNotification({ status: 'success', message: `"${itemName}" edited successfully!` });
        navigate("/dashboard/items");
      } catch (error) {
        console.error('Error saving item: ', error);
        addNotification({ status: 'error', message: 'Error saving item.' });
      }
    } */
      // Display an error notification instead of saving the item
    addNotification({
      status: 'error',
      message: 'The storage term has ended. Editing items is no longer permitted.',
    });
  };

  return (
    <Box sx={{ p: 3, flexGrow: 1 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Edit Item</Typography>

      {itemConfig && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 2, backgroundColor: 'primary.main', color: 'white', textAlign: 'center', 
                      backgroundImage: 'url(/images/background_pattern.webp)',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat', }}>
                <Avatar
                  src={itemConfig.icon}
                  alt={itemConfig.label}
                  sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
                />
                <Typography variant="subtitle2" sx={{mt: 3}}> Item Type: </Typography>
                <Typography variant="h5" >{itemConfig.label}</Typography>
              </Paper>
                <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{width: "100%", mt: 2}}>
                    Back
                </Button>

                <Button  onClick={handleDialogOpen} variant="outlined" sx={{color: "red",  width: "100%", mt: 2, borderColor: "red", display: {lg: "none", md: "flex"}}}>
                    <DeleteOutline sx={{mr: 1}}> </DeleteOutline> Delete 
                </Button>
            </Grid>
            <Grid item xs={12} md={8}>

                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Tabs value={tabIndex} onChange={handleTabChange} sx={{mb: 2}} aria-label="edit item tabs">
                        <Tab label="Edit Details" />
                        <Tab label="Upload Images" />
                        {itemConfig.category === 'Container' && <Tab label="Edit Contents" />}
                    </Tabs>
                    <Button  onClick={handleDialogOpen} variant="outlined" sx={{color: "red", height: "100%", borderColor: "red", display: {lg: "flex", xs: "none"}}}>
                        <DeleteOutline sx={{mr: 1}}> </DeleteOutline> Delete 
                    </Button>
                </Box>
              {tabIndex === 0 && (
                <Paper sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ mb: 3 }}>Edit Details</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Item Name"
                        variant="outlined"
                        value={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Item Description"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={itemDescription}
                        onChange={(e) => setItemDescription(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleSave}>
                        Save
                      </Button>
                      <Button variant="outlined" color="secondary">
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              )}
              {tabIndex === 1 && (
                <Paper sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ mb: 3 }}>Upload Images</Typography>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-image"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <label htmlFor="upload-image">
                    <IconButton color="primary" component="span">
                      <AddAPhoto />
                    </IconButton>
                    <Typography variant="body1" component="span">
                      Upload Image
                    </Typography>
                  </label>
                  {previewImage && (
                    <Box sx={{ mt: 2 }}>
                      <img src={previewImage} alt="Preview" style={{ width: '100%', maxWidth: 400 }} />
                    </Box>
                  )}
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSave}>
                      Save
                    </Button>
                  </Grid>
                </Paper>
              )}
              {tabIndex === 2 && itemConfig.category === 'Container' && (
                <Paper sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ mb: 3 }}>Edit Contents</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Item Contents"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={itemContents}
                        onChange={(e) => setItemContents(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleSave}>
                        Save
                      </Button>
                      <Button variant="outlined" color="secondary">
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
          >
            <DialogTitle id="delete-dialog-title">
              Confirm Delete
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
              <DeleteOutline sx={{ fontSize: 60, color: 'gray', mr: 2 }} />
              <Typography variant="body1">
                Are you sure you want to delete {itemName ? ('"' + itemName + '"?') : " this item?"}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteItem} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
    </Box>
  );
};

export default EditItem;

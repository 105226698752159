import React from 'react';
import { Drawer, List, ListItem, ListItemText, Typography, Box, ListItemIcon, Divider, useMediaQuery, IconButton } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';
import SchoolIcon from '@mui/icons-material/School';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpIcon from '@mui/icons-material/Help';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Sidebar = ({ open, toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userClaims } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const userMenuItems = [
    { text: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
    { text: 'My Items', path: '/dashboard/items', icon: <InventoryIcon /> },
    { text: 'Appointments', path: '/dashboard/appointments', icon: <CalendarMonthIcon /> },
    { text: 'Supplies', path: '/dashboard/supplies', icon: <LocalShippingIcon /> },
    { text: 'Storage Plan', path: '/dashboard/plan', icon: <StorageIcon /> },
    { text: 'Settings', path: '/dashboard/settings', icon: <SettingsIcon /> },
    { text: 'Support', path: '/dashboard/support', icon: <SupportAgentIcon /> },
  ];

  const adminMenuItems = [
    {
      section: 'Analytics',
      items: [
        { text: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
        { text: 'System Monitoring', path: '/dashboard/system-monitoring', icon: <AssessmentIcon /> },
        { text: 'Usage Statistics', path: '/dashboard/usage-statistics', icon: <BarChartIcon /> },
      ],
    },
    {
      section: 'Management',
      items: [
        { text: 'User Dashboard', path: '/dashboard/users', icon: <PeopleIcon /> },
        { text: 'Container Settings', path: '/dashboard/containers', icon: <InventoryIcon /> },
        { text: 'University Settings', path: '/dashboard/universities', icon: <SchoolIcon /> },
        { text: 'System Settings', path: '/dashboard/system-settings', icon: <BuildIcon /> },
      ],
    },
    {
      section: 'Sales',
      items: [
        { text: 'Sales Dashboard', path: '/dashboard/sales-dashboard', icon: <AttachMoneyIcon /> },
        { text: 'Revenue Reports', path: '/dashboard/revenue-reports', icon: <BarChartIcon /> },
      ],
    },
    {
      section: 'Support',
      items: [
        { text: 'Support Tickets', path: '/dashboard/support-tickets', icon: <SupportAgentIcon /> },
        { text: 'Customer Feedback', path: '/dashboard/customer-feedback', icon: <FeedbackIcon /> },
        { text: 'Help Center', path: '/dashboard/help-center', icon: <HelpIcon /> },
      ],
    },
  ];

  const menuItems = userClaims.role === 'admin' ? adminMenuItems : userMenuItems;

  const handleMenuItemClick = (path) => {
    navigate(path);
    if (isMobile) {
      toggleSidebar(); // Close the sidebar on mobile after navigation
    }
  };

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor="left"
      open={open}
      onClose={isMobile ? toggleSidebar : undefined}
      ModalProps={{ keepMounted: true }}
    >
      <Box sx={{ width: { md: 240, xs: '100vw' }, backgroundColor: 'secondary.alternate', height: '170%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', pt: 2, pl: 1 }}>
          <IconButton onClick={toggleSidebar} sx={{ display: { md: 'none', xs: 'block' }, color: 'white', mt: 2, pl: 2, pr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Link to="/" sx={{ height: '18px' }}>
            <Box
              component="img"
              src="/images/SummerStore-Logo.webp"
              alt="SummerStore Logo"
              sx={{
                height: '15px',
                width: 'auto',
                mt: { md: 3, xs: '17px' },
                pl: 2,
              }}
            />
          </Link>
        </Box>
        <Typography variant="subtitle2" sx={{ color: 'white', p: 2, mt: 2, textAlign: 'left', width: '100%' }}>
          Welcome, <span style={{ fontWeight: 'bold' }}>{userClaims.firstName || 'User'}!</span>
        </Typography>
        <List>
          {userClaims.role === 'admin' ? (
            adminMenuItems.map((section, index) => (
              <React.Fragment key={section.section}>
                <Typography variant="subtitle2" sx={{ color: 'white', pl: 3, mt: index === 0 ? 0 : 2, mb: 1 }}>
                  {section.section}
                </Typography>
                {section.items.map((item) => (
                  <ListItem
                    button
                    key={item.text}
                    onClick={() => handleMenuItemClick(item.path)}
                    sx={{
                      color: 'white',
                      fontSize: '0.7em',
                      backgroundColor: location.pathname === item.path ? '#244A83' : 'inherit',
                      '&:hover': {
                        backgroundColor: '#244A83',
                      },
                      pl: 3,
                      borderLeft: location.pathname === item.path ? '5px solid #3B88EA' : 'none',
                    }}
                  >
                    <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} primaryTypographyProps={{ fontWeight: location.pathname === item.path ? 'bold' : 'normal' }} />
                  </ListItem>
                ))}
                {index < adminMenuItems.length - 1 && <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', my: 1 }} />}
              </React.Fragment>
            ))
          ) : (
            <React.Fragment>
              {userMenuItems.map((item) => (
                <ListItem
                  button
                  key={item.text}
                  onClick={() => handleMenuItemClick(item.path)}
                  sx={{
                    color: 'white',
                    backgroundColor: location.pathname === item.path ? '#244A83' : 'inherit',
                    '&:hover': {
                      backgroundColor: '#244A83',
                    },
                    pl: 3,
                    mt: 1,
                    borderLeft: location.pathname === item.path ? '5px solid #3B88EA' : 'none',
                  }}
                >
                  <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} primaryTypographyProps={{ fontWeight: location.pathname === item.path ? 'bold' : 'normal' }} />
                </ListItem>
              ))}
            </React.Fragment>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default React.memo(Sidebar);

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Tabs,
  MenuItem,
  Tab,
  IconButton,
  Menu,
  TextField,
  InputAdornment,
  Checkbox,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { Inbox, ShoppingCart, Edit, FilterAltOutlined, FilterAlt, ArrowDropDown, ArrowDropUp, Search, ClearAll, AccessTime } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { auth, db } from '../firebase';
import { getFirestore, doc, getDoc, addDoc, collection, getDocs, setDoc } from "firebase/firestore"; // Import Firestore functions

const UserDetail = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null); // Initial state as null
  const [loading, setLoading] = useState(true); // Loading state
  const [editing, setEditing] = useState(false);
  const [status, setStatus] = useState('');
  const [roles, setRoles] = useState('');
  const [tab, setTab] = useState('items');
  const [filterAnchors, setFilterAnchors] = useState({});
  const [filters, setFilters] = useState({});
  const [filterSearch, setFilterSearch] = useState('');
  const [deactivationReason, setDeactivationReason] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [tabLoading, setTabLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const fetchUser = async () => {
      const db = getFirestore();
      const userDoc = await getDoc(doc(db, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();

        const regDate = userData.registrationDate ? new Date(userData.registrationDate.seconds * 1000).toLocaleDateString() : 'N/A';
        userData.registrationDate = regDate;

        setUser(userData);
        setStatus(userData.status);
        setRoles(userData.roles);
        setLoading(false);
      } else {
        console.log("No such document!");
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  useEffect(() => {
    if (user && tab && !user[tab]) {
      setTabLoading(true);
      const fetchTabData = async () => {
        const db = getFirestore();
        const tabCollection = tab === 'activityHistory' ? 'activity' : tab;
        const tabQuery = tab === 'activityHistory'
          ? collection(db, `users/${userId}/activity`)
          : collection(db, `users/${userId}/${tabCollection}`);
        const tabDocs = await getDocs(tabQuery);
        const tabData = tabDocs.docs.map(doc => doc.data());
  
        setUser(prevUser => ({
          ...prevUser,
          [tab]: tabData
        }));
        setTabLoading(false);
      };
  
      fetchTabData();
    }
  }, [tab, user, userId]);

  const handleEdit = () => {
    setEditing(!editing);
  };

  const handleSave = () => {
    // Save user data
    setEditing(false);
}

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleEditItemClick = (itemId) => {
    navigate(`/dashboard/containers/${user.uid}/${itemId}`);
  };

  const handleFilterClick = (event, column) => {
    setFilterAnchors({ ...filterAnchors, [column]: event.currentTarget });
  };

  const handleFilterClose = (column) => {
    setFilterAnchors({ ...filterAnchors, [column]: null });
  };

  const handleFilterChange = (column, value) => {
    const columnFilters = filters[column] || [];
    const newFilters = columnFilters.includes(value)
      ? columnFilters.filter((v) => v !== value)
      : [...columnFilters, value];
    setFilters({ ...filters, [column]: newFilters });
  };

  const handleFilterSearchChange = (event) => {
    setFilterSearch(event.target.value);
  };

  const handleSelectAll = (column) => {
    const columnValues = user[tab].map((row) => row[column])
      .filter((value, index, self) => self.indexOf(value) === index);
    setFilters({ ...filters, [column]: columnValues });
  };

  const handleDeselectAll = (column) => {
    setFilters({ ...filters, [column]: [] });
  };

  const handleSort = (column) => {
    const isAsc = sortOrder[column] === 'asc';
    setSortOrder({ [column]: isAsc ? 'desc' : 'asc' });
    const sortedData = [...user[tab]].sort((a, b) => {
      if (a[column] < b[column]) return isAsc ? -1 : 1;
      if (a[column] > b[column]) return isAsc ? 1 : -1;
      return 0;
    });
    setUser({ ...user, [tab]: sortedData });
  };

  const applyFilters = (data) => {
    return data.filter((row) =>
      Object.keys(filters).every((column) =>
        filters[column]?.length ? filters[column].includes(row[column]) : true
      )
    );
  };

  const handleDeactivateReactivate = (action) => {
    setDialogAction(action);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDeactivationReason('');
  };

  const handleDialogConfirm = () => {
    if (dialogAction === 'deactivate' && deactivationReason.trim() !== '') {
      setStatus('Deactivated');
      setUser({ ...user, status: 'Deactivated' });
      setDialogOpen(false);
    } else if (dialogAction === 'reactivate') {
      setStatus('Active');
      setUser({ ...user, status: 'Active' });
      setDialogOpen(false);
    }
  };

  if (loading) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress />
        </Box>
        );
  }

  if (!userId || !user) {
    return <Typography>Unable to load. Try refreshing, or check user ID.</Typography>;
  }

  const filteredData = user[tab] ? applyFilters(user[tab]) : [];

  return (
    <Box sx={{ p: 3 }}>
      <Button variant="outlined" color="primary" onClick={() => navigate('/dashboard/users')} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>User Details</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" gutterBottom>Profile Information</Typography>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleDeactivateReactivate(status === 'Active' ? 'deactivate' : 'reactivate')}
                  sx={{ mr: 1 }}
                >
                  {status === 'Active' ? 'Deactivate' : 'Reactivate'}
                </Button>
                {editing ? (
                  <Button variant="contained" color="secondary" onClick={handleSave}>Save</Button>
                ) : (
                  <Button variant="outlined" color="secondary" onClick={handleEdit}>Edit</Button>
                )}
              </Box>
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="body1"><strong>First Name:</strong> {user.firstName}</Typography>
              <Typography variant="body1"><strong>Last Name:</strong> {user.lastName}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
              <Typography variant="body1"><strong>University:</strong> {user.university}</Typography>
              <Typography variant="body1"><strong>Registration Date:</strong> {user.registrationDate}</Typography>
              <Typography variant="body1"><strong>Status:</strong> {status}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Role</InputLabel>
                <Select
                  value={roles}
                  onChange={(e) => setRoles(e.target.value)}
                  label="Roles"
                  disabled={!editing}
                >
                  <MenuItem value="Student">Student</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Support">Support</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} sx={{ flexGrow: 1 }}>
          <Paper sx={{ mb: 2, flexGrow: 1, width: '100%' }}>
            <Tabs value={tab} onChange={handleTabChange} aria-label="purchases or items" variant="fullWidth">
              <Tab icon={<Inbox />} label="Items" value="items" iconPosition="start" />
              <Tab icon={<ShoppingCart />} label="Purchases" value="purchases" iconPosition="start" />
              <Tab icon={<AccessTime />} label="Activity" value="activityHistory" iconPosition="start" />
            </Tabs>
          </Paper>
          {tabLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
              <CircularProgress />
            </Box>
          ) : user[tab] && user[tab].length > 0 ? (
            <Paper sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">{tab === 'items' ? 'Items' : tab === 'purchases' ? 'Purchases' : 'Activity'}</Typography>
                {Object.keys(filters).some(column => filters[column]?.length) && (
                  <Button
                    variant="outlined"
                    startIcon={<ClearAll />}
                    onClick={() => setFilters({})}
                  >
                    Clear Filters
                  </Button>
                )}
              </Box>
              <TableContainer sx={{ flexGrow: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tab === 'items' ? (
                        ['id', 'containerId', 'type', 'description'].map((column) => (
                          <TableCell key={column}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography>{column.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</Typography>
                              <IconButton
                                size="small"
                                onClick={(e) => handleFilterClick(e, column)}
                                sx={{ fontWeight: filters[column]?.length ? 'bold' : 'normal' }}
                              >
                                {filters[column]?.length ? <FilterAlt /> : <FilterAltOutlined sx={{ strokeWidth: 2 }} />}
                              </IconButton>
                              <Menu
                                anchorEl={filterAnchors[column]}
                                open={Boolean(filterAnchors[column])}
                                onClose={() => handleFilterClose(column)}
                              >
                                <Box sx={{ p: 1 }}>
                                  <TextField
                                    variant="outlined"
                                    placeholder="Search..."
                                    fullWidth
                                    value={filterSearch}
                                    onChange={handleFilterSearchChange}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Search />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Button onClick={() => handleSelectAll(column)}>Select All</Button>
                                    <Button onClick={() => handleDeselectAll(column)}>Deselect All</Button>
                                  </Box>
                                  <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                    {user[tab]?.map((row) => row[column])
                                      .filter((value, index, self) => self.indexOf(value) === index)
                                      .filter((value) => value.toString().toLowerCase().includes(filterSearch.toLowerCase()))
                                      .map((value) => (
                                        <MenuItem key={value} onClick={() => handleFilterChange(column, value)}>
                                          <Checkbox checked={filters[column]?.includes(value) || false} size="small" />
                                          <ListItemText primary={value} />
                                        </MenuItem>
                                      ))}
                                  </Box>
                                  <Divider />
                                  <Button onClick={() => handleSort(column)}>
                                    {sortOrder[column] === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />}
                                    Sort
                                  </Button>
                                </Box>
                              </Menu>
                            </Box>
                          </TableCell>
                        ))
                      ) : tab === 'purchases' ? (
                        ['description', 'amount', 'date'].map((column) => (
                          <TableCell key={column}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography>{column.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</Typography>
                              <IconButton
                                size="small"
                                onClick={(e) => handleFilterClick(e, column)}
                                sx={{ fontWeight: filters[column]?.length ? 'bold' : 'normal' }}
                              >
                                {filters[column]?.length ? <FilterAlt /> : <FilterAltOutlined sx={{ strokeWidth: 2 }} />}
                              </IconButton>
                              <Menu
                                anchorEl={filterAnchors[column]}
                                open={Boolean(filterAnchors[column])}
                                onClose={() => handleFilterClose(column)}
                              >
                                <Box sx={{ p: 1 }}>
                                  <TextField
                                    variant="outlined"
                                    placeholder="Search..."
                                    fullWidth
                                    value={filterSearch}
                                    onChange={handleFilterSearchChange}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Search />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Button onClick={() => handleSelectAll(column)}>Select All</Button>
                                    <Button onClick={() => handleDeselectAll(column)}>Deselect All</Button>
                                  </Box>
                                  <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                    {user[tab]?.map((row) => row[column])
                                      .filter((value, index, self) => self.indexOf(value) === index)
                                      .filter((value) => value.toString().toLowerCase().includes(filterSearch.toLowerCase()))
                                      .map((value) => (
                                        <MenuItem key={value} onClick={() => handleFilterChange(column, value)}>
                                          <Checkbox checked={filters[column]?.includes(value) || false} size="small" />
                                          <ListItemText primary={value} />
                                        </MenuItem>
                                      ))}
                                  </Box>
                                  <Divider />
                                  <Button onClick={() => handleSort(column)}>
                                    {sortOrder[column] === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />}
                                    Sort
                                  </Button>
                                </Box>
                              </Menu>
                            </Box>
                          </TableCell>
                        ))
                      ) : (
                        ['activity', 'date'].map((column) => (
                          <TableCell key={column}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography>{column.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</Typography>
                              <IconButton
                                size="small"
                                onClick={(e) => handleFilterClick(e, column)}
                                sx={{ fontWeight: filters[column]?.length ? 'bold' : 'normal' }}
                              >
                                {filters[column]?.length ? <FilterAlt /> : <FilterAltOutlined sx={{ strokeWidth: 2 }} />}
                              </IconButton>
                              <Menu
                                anchorEl={filterAnchors[column]}
                                open={Boolean(filterAnchors[column])}
                                onClose={() => handleFilterClose(column)}
                              >
                                <Box sx={{ p: 1 }}>
                                  <TextField
                                    variant="outlined"
                                    placeholder="Search..."
                                    fullWidth
                                    value={filterSearch}
                                    onChange={handleFilterSearchChange}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Search />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Button onClick={() => handleSelectAll(column)}>Select All</Button>
                                    <Button onClick={() => handleDeselectAll(column)}>Deselect All</Button>
                                  </Box>
                                  <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                    {user[tab]?.map((row) => row[column])
                                      .filter((value, index, self) => self.indexOf(value) === index)
                                      .filter((value) => value.toString().toLowerCase().includes(filterSearch.toLowerCase()))
                                      .map((value) => (
                                        <MenuItem key={value} onClick={() => handleFilterChange(column, value)}>
                                          <Checkbox checked={filters[column]?.includes(value) || false} size="small" />
                                          <ListItemText primary={value} />
                                        </MenuItem>
                                      ))}
                                  </Box>
                                  <Divider />
                                  <Button onClick={() => handleSort(column)}>
                                    {sortOrder[column] === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />}
                                    Sort
                                  </Button>
                                </Box>
                              </Menu>
                            </Box>
                          </TableCell>
                        ))
                      )}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((item) => (
                      <TableRow key={item.id}>
                        {tab === 'items' ? (
                          <>
                            <TableCell sx={{ py: 0 }}>{item.id}</TableCell>
                            <TableCell sx={{ py: 0 }}>{item.containerId}</TableCell>
                            <TableCell sx={{ py: 0 }}>{item.type}</TableCell>
                            <TableCell sx={{ py: 0 }}>{item.description}</TableCell>
                            <TableCell sx={{ py: 0 }}>
                              <IconButton onClick={() => handleEditItemClick(item.id)}>
                                <Edit />
                              </IconButton>
                            </TableCell>
                          </>
                        ) : tab === 'purchases' ? (
                          <>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                            <TableCell>{item.date}</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>{item.activity}</TableCell>
                            <TableCell>{item.date}</TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper> 
          ) : (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <Typography>This data was not found for this user. If you believe this is an error, contact IT.</Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {dialogAction === 'deactivate' ? 'Confirm Deactivation' : 'Confirm Reactivation'}
        </DialogTitle>
        <DialogContent>
          {dialogAction === 'deactivate' ? (
            <>
              <DialogContentText>
                Please provide a reason for deactivating the account.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Reason for Deactivation"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
                value={deactivationReason}
                onChange={(e) => setDeactivationReason(e.target.value)}
              />
            </>
          ) : (
            <DialogContentText>
              Are you sure you want to reactivate the account?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm} color="secondary" disabled={dialogAction === 'deactivate' && !deactivationReason.trim()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserDetail;

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Paper,
} from '@mui/material';
import {
  Inventory,
  LocalShipping,
} from '@mui/icons-material';

const Appointments = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isClosed, setIsClosed] = useState(true); // Assuming the sections are closed for now

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // Placeholder data for the appointments
  const appointments = {
    supplies: {
      time: 'April 5th, 2025, 10:00 AM',
      location: 'Main Storage Facility, Building A',
    },
    dropOff: {
      time: 'April 6th, 2025, 2:00 PM',
      location: 'Campus Center, Lot 3',
    },
    pickUp: {
      time: 'April 7th, 2025, 4:00 PM',
      location: 'Dormitory Entrance, Building C',
    },
  };

  return (
    <Box sx={{ p: 3, flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Appointments
      </Typography>
      <Typography variant="body1" gutterBottom>
        Manage your scheduled appointments for supplies, drop off, and pick up. Below you can see the details of your upcoming appointments and make any necessary changes.
      </Typography>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab icon={<Inventory />} label="Supplies Pickup" />
          <Tab icon={<LocalShipping />} label="Drop Off Items" />
          <Tab icon={<LocalShipping />} label="Pick Up Items" />
        </Tabs>
      </Paper>
      <Box sx={{ position: 'relative', mt: 3 }}>
        {isClosed && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly opaque white background
              zIndex: 2,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'text.secondary'}}>
                <LocalShipping sx={{textAlign: 'center', fontSize: "2.5rem", mb: 2}}></LocalShipping>
                
                <Typography variant="h5" sx={{ color: 'text.secondary', fontWeight: 'bold', textAlign: 'center' }}>
                This storage term is complete.
                <br />
                </Typography>
                <Typography variant="subtitle2" sx={{textAlign: 'center', color: 'text.secondary'}}>
                This section will reopen <strong> April 1st, 2025</strong>.
                </Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ filter: isClosed ? 'blur(5px)' : 'none', pointerEvents: isClosed ? 'none' : 'auto' }}>
          <Paper elevation={3} sx={{ p: 3 }}>
            {tabIndex === 0 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Scheduled Supplies Appointment
                </Typography>
                <Typography variant="body1">
                  <strong>Time:</strong> {appointments.supplies.time}
                </Typography>
                <Typography variant="body1">
                  <strong>Location:</strong> {appointments.supplies.location}
                </Typography>
              </Box>
            )}
            {tabIndex === 1 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Scheduled Drop Off Appointment
                </Typography>
                <Typography variant="body1">
                  <strong>Time:</strong> {appointments.dropOff.time}
                </Typography>
                <Typography variant="body1">
                  <strong>Location:</strong> {appointments.dropOff.location}
                </Typography>
              </Box>
            )}
            {tabIndex === 2 && (
              <Box>
                <Typography variant="h6" gutterBottom>
                  Scheduled Pick Up Appointment
                </Typography>
                <Typography variant="body1">
                  <strong>Time:</strong> {appointments.pickUp.time}
                </Typography>
                <Typography variant="body1">
                  <strong>Location:</strong> {appointments.pickUp.location}
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default Appointments;

import React, { createContext, useState, useContext } from 'react';
import NotificationComponent from '../components/NotificationComponent'; // Import the NotificationComponent

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (notification) => {
    const id = Math.random().toString();
    setNotifications((prev) => [...prev, { ...notification, id }]);
  };

  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <NotificationComponent notifications={notifications} removeNotification={removeNotification} />
    </NotificationContext.Provider>
  );
};

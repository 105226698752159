// src/components/Layout.js
import React from 'react';
import { CssBaseline, Box, ThemeProvider, useMediaQuery } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import Overlay from './Overlay';

const Layout = ({ theme, menuOpen, setMenuOpen, sidebarOpen, toggleSidebar, breadcrumb, children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden', pb: 0, mb: 0 }}>
        <CssBaseline />
        <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            transition: 'margin 0.3s ease',
            marginLeft: sidebarOpen && !isMobile ? '240px' : '0',
          }}
        >
          <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} toggleSidebar={toggleSidebar} breadcrumb={breadcrumb} />
          <Box sx={{ flexGrow: 1, padding: 2, overflow: 'auto' }}>
            {children}
          </Box>
        </Box>
        {isMobile && <Overlay open={sidebarOpen} onClick={toggleSidebar} />}
      </Box>
    </ThemeProvider>
  );
};

export default Layout;

// src/pages/Pricing.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import StoragePlan from './StoragePlan'; // Adjust the import path if necessary

const Pricing = () => {
  return (
    <Box sx={{mb: 0, height: '100vh' }}>
      <NavBar />
      <Container maxWidth="xl" sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h3" align="center" gutterBottom>
          Storage Plans
        </Typography>
        <Typography variant="body1" align="center" >
          Choose the plan that best suits your needs. Bundle and save, or pay per item.
        </Typography>
        <StoragePlan />
      </Container>
      <Footer />
    </Box>
  );
};

export default Pricing;

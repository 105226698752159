// src/components/Footer.js
import React from 'react';
import { Box, Typography, Grid, Link, Container } from '@mui/material';
import { Twitter, Facebook, Instagram, Pinterest } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';

const Footer = () => {
  const { currentUser } = useAuth();

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.alternate',
        color: 'white',
        mt: 'auto', // This ensures the footer is pushed to the bottom
        pt: 8,
        pb: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Typography variant="h4" gutterBottom>
              <span style={{ fontWeight: 'bold' }}>Summer</span>Store
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              We make storing your belongings easy and affordable. We pick up, store, and deliver your items back to campus.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', maxWidth: '200px' }}>
              <Link href="#" color="inherit"><Facebook sx={{ fontSize: 32 }} /></Link>
              <Link href="http://instagram.com/summerstore.us/" color="inherit"><Instagram sx={{ fontSize: 32 }} /></Link>
              <Link href="#" color="inherit"><Pinterest sx={{ fontSize: 32 }} /></Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ marginLeft: 'auto' }}>
            <Typography variant="h6" gutterBottom>Our Services</Typography>
            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>Storage Plans</Link>
            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>Price Comparison</Link>
            <Link href="/#faq" color="inherit" sx={{ display: 'block', mb: 1 }}>FAQs</Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom>Company</Typography>
            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>About Us</Link>
            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>Contact Us</Link>
            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>Careers</Link>
            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>Privacy Policy</Link>
            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>Terms of Service</Link>
          </Grid>
          {currentUser && (
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>Quick Links</Typography>
              <Link href="/dashboard" color="inherit" sx={{ display: 'block', mb: 1 }}>Dashboard</Link>
              <Link href="/dashboard/items" color="inherit" sx={{ display: 'block', mb: 1 }}>My Items</Link>
              <Link href="/dashboard/plan" color="inherit" sx={{ display: 'block', mb: 1 }}>Change Plan</Link>
              <Link href="/dashboard/settings" color="inherit" sx={{ display: 'block', mb: 1 }}>Account Settings</Link>
              <Link href="/dashboard/inbox" color="inherit" sx={{ display: 'block', mb: 1 }}>Inbox</Link>
            </Grid>
          )}
        </Grid>
        <Box sx={{ mt: 8, borderTop: '1px solid #444', pt: 4 }}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                The entirety of this site is protected by copyright © 2024 SummerStore, LCC.
                <br />
                2389 Main St., STE 100, Glastonbury, CT, 06033, United States
              </Typography>
            </Grid>
            { /** 
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <img src="/images/Visa.jpg" alt="Visa" style={{ maxWidth: '50px', marginRight: '10px' }} />
              <img src="/images/Mastercard.jpg" alt="MasterCard" style={{ maxWidth: '50px', marginRight: '10px' }} />
              <img src="/images/Amex.jpg" alt="American Express" style={{ maxWidth: '50px', marginRight: '10px' }} />
              <img src="/images/Discover.jpg" alt="Discover" style={{ maxWidth: '50px', marginRight: '10px' }} />
              <img src="/images/ApplePay.jpg" alt="Apple Pay" style={{ maxWidth: '50px', marginRight: '10px' }} />
            </Grid> **/
            }
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

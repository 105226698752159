// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA3XnlhLUF_QQrzKV3ZgwCcMl46zQo0Ddg",
    authDomain: "summerstore-3881e.firebaseapp.com",
    projectId: "summerstore-3881e",
    storageBucket: "summerstore-3881e.appspot.com",
    messagingSenderId: "1076483400800",
    appId: "1:1076483400800:web:f897373bf0591f0ef4cf05",
    measurementId: "G-X86XY1M6K8"
  };
  

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };

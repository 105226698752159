import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Tooltip
} from '@mui/material';
import { Delete, Edit, ExpandMore, Add } from '@mui/icons-material';
import { db } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useNotification } from '../contexts/NotificationContext';

const UniversityAppointments = () => {
  const { universityID } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDates, setSelectedDates] = useState([]);
  const [containerLocations, setContainerLocations] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [massDeleteDialogOpen, setMassDeleteDialogOpen] = useState(false);
  const [massEditDialogOpen, setMassEditDialogOpen] = useState(false);
  const { addNotification } = useNotification();

  const [newAppointment, setNewAppointment] = useState({
    title: '',
    start: '',
    end: '',
    maxCustomers: 0,
    customers: [],
    storageCoordinator: '',
    increment: '',
    type: 'Move In',
    containerLocationIDs: [],
  });

  const [multipleValues, setMultipleValues] = useState({
    title: false,
    maxCustomers: false,
    storageCoordinator: false,
    type: false,
    containerLocationIDs: false,
  });

  useEffect(() => {
    const fetchContainerLocations = async () => {
      const containerCollection = collection(db, `universities/${universityID}/containerLocations`);
      const containerSnapshot = await getDocs(containerCollection);
      const containerList = containerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContainerLocations(containerList);
    };

    const fetchAppointments = async () => {
      const appointmentCollection = collection(db, `universities/${universityID}/appointments`);
      const appointmentSnapshot = await getDocs(appointmentCollection);
      const appointmentList = appointmentSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          start: data.start?.seconds ? new Date(data.start.seconds * 1000) : new Date(data.start),
          end: data.end?.seconds ? new Date(data.end.seconds * 1000) : new Date(data.end),
        };
      });
      setAppointments(appointmentList);
    };

    if (universityID) {
      fetchContainerLocations();
      fetchAppointments();
    }
  }, [universityID]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTabChange = (event, newValue) => setSelectedTab(newValue);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment({ ...newAppointment, [name]: value });
  };

  const handleDateChange = (e) => {
    const dates = e.target.value.split(',').map(date => new Date(date.trim()));
    setSelectedDates(dates);
  };

  const updateContainerLocationsWithAppointments = async (appointmentID, containerLocationIDs) => {
    for (const containerLocationID of containerLocationIDs) {
      const containerDocRef = doc(db, `universities/${universityID}/containerLocations`, containerLocationID);
      await updateDoc(containerDocRef, {
        appointmentIDs: arrayUnion(appointmentID)
      });
    }
  };

  const handleAddAppointments = async () => {
    const { start, end, increment, ...rest } = newAppointment;
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);
    const incrementMinutes = parseInt(increment) || 0;
    const newAppointments = [];

    selectedDates.forEach(date => {
      let currentStart = new Date(date);
      currentStart.setHours(startHour, startMinute, 0, 0);

      let currentEnd = new Date(date);
      currentEnd.setHours(endHour, endMinute, 0, 0);

      if (incrementMinutes > 0) {
        while (currentStart < currentEnd) {
          const newEnd = new Date(currentStart);
          newEnd.setMinutes(newEnd.getMinutes() + incrementMinutes);

          newAppointments.push({
            ...rest,
            start: new Date(currentStart),
            end: new Date(newEnd),
          });

          currentStart = newEnd;
        }
      } else {
        newAppointments.push({
          ...rest,
          start: new Date(currentStart),
          end: new Date(currentEnd),
        });
      }
    });

    for (const appointment of newAppointments) {
      const docRef = await addDoc(collection(db, `universities/${universityID}/appointments`), appointment);
      await updateContainerLocationsWithAppointments(docRef.id, appointment.containerLocationIDs);
      setAppointments(prevAppointments => [...prevAppointments, { id: docRef.id, ...appointment }]);
      addNotification({ status: 'success', message: 'Appointment added successfully!' });
    }

    handleClose();
  };

  const handleEditAppointment = async (index) => {
    // Implement edit functionality
    addNotification({ status: 'success', message: 'Appointment edited successfully!' });
  };

  const handleDeleteAppointment = async () => {
    if (appointmentToDelete) {
      await deleteDoc(doc(db, `universities/${universityID}/appointments`, appointmentToDelete));
      const updatedAppointments = appointments.filter(app => app.id !== appointmentToDelete);
      setAppointments(updatedAppointments);
      addNotification({ status: 'success', message: 'Appointment deleted successfully!' });
      setAppointmentToDelete(null);
      setDeleteDialogOpen(false);
    }
  };

  const handleBatchDeleteAppointments = async () => {
    const batchDeletePromises = selectedAppointments.map(appointmentID =>
      deleteDoc(doc(db, `universities/${universityID}/appointments`, appointmentID))
    );
    await Promise.all(batchDeletePromises);
    const updatedAppointments = appointments.filter(app => !selectedAppointments.includes(app.id));
    setAppointments(updatedAppointments);
    setSelectedAppointments([]);
    addNotification({ status: 'success', message: 'Selected appointments deleted successfully!' });
    setMassDeleteDialogOpen(false);
  };

  const handleBatchEditAppointments = async () => {
    const editedValues = {};
    Object.keys(newAppointment).forEach(key => {
      if (newAppointment[key] !== '' && newAppointment[key] !== `Multiple ${key} selected...`) {
        editedValues[key] = newAppointment[key];
      }
    });

    // Remove undefined values from editedValues
    Object.keys(editedValues).forEach(key => {
      if (editedValues[key] === undefined) {
        delete editedValues[key];
      }
    });

    const batchEditPromises = selectedAppointments.map(appointmentID =>
      updateDoc(doc(db, `universities/${universityID}/appointments`, appointmentID), editedValues)
    );

    await Promise.all(batchEditPromises);

    const updatedAppointments = appointments.map(app => {
      if (selectedAppointments.includes(app.id)) {
        return { ...app, ...editedValues };
      }
      return app;
    });

    setAppointments(updatedAppointments);
    setSelectedAppointments([]);
    addNotification({ status: 'success', message: 'Selected appointments edited successfully!' });
    setMassEditDialogOpen(false);
  };

  const openDeleteDialog = (appointmentID) => {
    setAppointmentToDelete(appointmentID);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setAppointmentToDelete(null);
    setDeleteDialogOpen(false);
  };

  const openMassDeleteDialog = () => {
    setMassDeleteDialogOpen(true);
  };

  const closeMassDeleteDialog = () => {
    setMassDeleteDialogOpen(false);
  };

  const openMassEditDialog = () => {
    setMassEditDialogOpen(true);
    // Pre-fill the fields with values or "Multiple values selected for this field" if there are differences
    const selectedAppoints = appointments.filter(app => selectedAppointments.includes(app.id));
    const newAppointmentValues = {};
    const multipleValuesDetected = {};

    Object.keys(newAppointment).forEach(key => {
      const allValues = selectedAppoints.map(app => app[key]);
      const uniqueValues = [...new Set(allValues)];

      if (uniqueValues.length === 1) {
        newAppointmentValues[key] = uniqueValues[0];
        multipleValuesDetected[key] = false;
      } else {
        newAppointmentValues[key] = '';
        multipleValuesDetected[key] = true;
      }
    });

    setNewAppointment(newAppointmentValues);
    setMultipleValues(multipleValuesDetected);
  };

  const closeMassEditDialog = () => {
    setMassEditDialogOpen(false);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedAppointments([]);
    } else {
      const allAppointmentIDs = appointments.map(app => app.id);
      setSelectedAppointments(allAppointmentIDs);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectDay = (appointments) => {
    const dayAppointmentIDs = appointments.map(app => app.id);
    if (dayAppointmentIDs.every(id => selectedAppointments.includes(id))) {
      setSelectedAppointments(selectedAppointments.filter(id => !dayAppointmentIDs.includes(id)));
    } else {
      setSelectedAppointments([...selectedAppointments, ...dayAppointmentIDs]);
    }
  };

  const handleSelectAppointment = (appointmentID) => {
    if (selectedAppointments.includes(appointmentID)) {
      setSelectedAppointments(selectedAppointments.filter(id => id !== appointmentID));
    } else {
      setSelectedAppointments([...selectedAppointments, appointmentID]);
    }
  };

  const groupAppointmentsByDayAndType = (appointments, type) => {
    const grouped = {};

    appointments
      .filter(appointment => appointment.type === type)
      .forEach(appointment => {
        const dateKey = appointment.start.toDateString();
        if (!grouped[dateKey]) {
          grouped[dateKey] = [];
        }
        grouped[dateKey].push(appointment);
      });

    return grouped;
  };

  const renderAppointmentTable = (appointmentsByDay) => (
    Object.keys(appointmentsByDay).map(dateKey => (
      <Accordion key={dateKey}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={appointmentsByDay[dateKey].every(app => selectedAppointments.includes(app.id))}
              onChange={() => handleSelectDay(appointmentsByDay[dateKey])}
            />
            <Typography>{dateKey}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                  </TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Max Customers</TableCell>
                  <TableCell>Storage Coordinator</TableCell>
                  <TableCell>Container Locations</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointmentsByDay[dateKey].map((appointment, index) => (
                  <TableRow key={index} selected={selectedAppointments.includes(appointment.id)}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAppointments.includes(appointment.id)}
                        onChange={() => handleSelectAppointment(appointment.id)}
                      />
                    </TableCell>
                    <TableCell>{appointment.title}</TableCell>
                    <TableCell>{appointment.start.toLocaleTimeString()}</TableCell>
                    <TableCell>{appointment.end.toLocaleTimeString()}</TableCell>
                    <TableCell>{appointment.maxCustomers}</TableCell>
                    <TableCell>{appointment.storageCoordinator}</TableCell>
                    <TableCell>
                      {appointment.containerLocationIDs.map(id => {
                        const location = containerLocations.find(loc => loc.id === id);
                        return location ? location.name : id;
                      }).join(', ')}
                    </TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={() => handleEditAppointment(index)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={() => openDeleteDialog(appointment.id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    ))
  );

  const moveInAppointments = groupAppointmentsByDayAndType(appointments, 'Move In');
  const moveOutAppointments = groupAppointmentsByDayAndType(appointments, 'Move Out');

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="h6" gutterBottom>
          University Appointments
        </Typography>
        <Box>
          {selectedAppointments.length > 0 && (
            <>
              <Tooltip title="Edit selected appointments">
                <Button variant="outlined" sx={{mr: 1}} onClick={openMassEditDialog}>
                  <Edit color="primary" /> Edit
                </Button>
              </Tooltip>
              <Tooltip title="Delete selected appointments">
                <Button variant="outlined" sx={{mr: 3, color: "red"}} onClick={openMassDeleteDialog}>
                  <Delete  /> Delete
                </Button>
              </Tooltip>
            </>
          )}
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleOpen} disabled={!universityID} >
            Add Appointments
          </Button>
        </Box>
      </Box>
      <Tabs value={selectedTab} onChange={handleTabChange} sx={{mb: 2}}>
        <Tab label="Move In Appointments" />
        <Tab label="Move Out Appointments" />
      </Tabs>
      {selectedTab === 0 && (
        <Box>
          {renderAppointmentTable(moveInAppointments)}
        </Box>
      )}
      {selectedTab === 1 && (
        <Box>
          {renderAppointmentTable(moveOutAppointments)}
        </Box>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Appointment</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="title"
            label="Title"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="dates"
            label="Dates (comma-separated YYYY-MM-DD)"
            fullWidth
            variant="outlined"
            onChange={handleDateChange}
          />
          <TextField
            margin="dense"
            name="start"
            label="Start Time"
            type="time"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="end"
            label="End Time"
            type="time"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="increment"
            label="Increment (minutes, leave blank for single appointment)"
            type="number"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="maxCustomers"
            label="Max Customers"
            type="number"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Storage Coordinator</InputLabel>
            <Select
              name="storageCoordinator"
              value={newAppointment.storageCoordinator}
              onChange={handleChange}
            >
              <MenuItem value="Coordinator A">Coordinator A</MenuItem>
              <MenuItem value="Coordinator B">Coordinator B</MenuItem>
              <MenuItem value="Coordinator C">Coordinator C</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={newAppointment.type}
              onChange={handleChange}
            >
              <MenuItem value="Move In">Move In</MenuItem>
              <MenuItem value="Move Out">Move Out</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Container Locations</InputLabel>
            <Select
              name="containerLocationIDs"
              multiple
              value={Array.isArray(newAppointment.containerLocationIDs) ? newAppointment.containerLocationIDs : []}
              onChange={handleChange}
            >
              {containerLocations.map(location => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddAppointments} color="primary">
            Add Appointments
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
          <Delete sx={{ fontSize: 60, color: 'gray', mr: 2 }} />
          <Typography variant="body1">
            Are you sure you want to delete this appointment?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAppointment} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={massDeleteDialogOpen}
        onClose={closeMassDeleteDialog}
        aria-labelledby="mass-delete-dialog-title"
        aria-describedby="mass-delete-dialog-description"
      >
        <DialogTitle id="mass-delete-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete the selected appointments?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMassDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleBatchDeleteAppointments} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={massEditDialogOpen}
        onClose={closeMassEditDialog}
        aria-labelledby="mass-edit-dialog-title"
        aria-describedby="mass-edit-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="mass-edit-dialog-title">
          Edit Appointments
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="title"
            label="Title"
            fullWidth
            variant="outlined"
            value={newAppointment.title}
            onChange={handleChange}
          />
          {multipleValues.title && <Typography color="textSecondary" sx={{fontSize: "0.8rem", textAlign: "right"}}>(Multiple values selected for this field)</Typography>}
          
          <TextField
            margin="dense"
            name="maxCustomers"
            label="Max Customers"
            type="number"
            fullWidth
            variant="outlined"
            value={newAppointment.maxCustomers}
            onChange={handleChange}
            sx={{mt: 3}}
          />
          {multipleValues.maxCustomers && <Typography color="textSecondary" sx={{fontSize: "0.8rem", textAlign: "right"}}>(Multiple values selected for this field)</Typography>}

          <FormControl fullWidth margin="dense"
            sx={{mt: 3}}>
            <InputLabel>Storage Coordinator</InputLabel>
            <Select
              name="storageCoordinator"
              value={newAppointment.storageCoordinator}
              onChange={handleChange}
            >
              <MenuItem value="Coordinator A">Coordinator A</MenuItem>
              <MenuItem value="Coordinator B">Coordinator B</MenuItem>
              <MenuItem value="Coordinator C">Coordinator C</MenuItem>
            </Select>
          </FormControl>
          {multipleValues.storageCoordinator && <Typography color="textSecondary" sx={{fontSize: "0.8rem", textAlign: "right"}}>(Multiple values selected for this field)</Typography>}
          
          <FormControl fullWidth margin="dense"
            sx={{mt: 3}}>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={newAppointment.type}
              onChange={handleChange}
            >
              <MenuItem value="Move In">Move In</MenuItem>
              <MenuItem value="Move Out">Move Out</MenuItem>
            </Select>
          </FormControl>
          {multipleValues.type && <Typography color="textSecondary" sx={{fontSize: "0.8rem", textAlign: "right"}}>(Multiple values selected for this field)</Typography>}
          
          <FormControl fullWidth margin="dense"
            sx={{mt: 3}}>
            <InputLabel>Container Locations</InputLabel>
            <Select
              name="containerLocationIDs"
              multiple
              value={Array.isArray(newAppointment.containerLocationIDs) ? newAppointment.containerLocationIDs : []}
              onChange={handleChange}
            >
              {containerLocations.map(location => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {multipleValues.containerLocationIDs && <Typography color="textSecondary" sx={{fontSize: "0.8rem", textAlign: "right"}}>(Multiple values selected for this field)</Typography>}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMassEditDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleBatchEditAppointments} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UniversityAppointments;

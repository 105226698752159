import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import RegistrationIcon from '@mui/icons-material/HowToReg';
import StorageIcon from '@mui/icons-material/Inventory';
import CostIcon from '@mui/icons-material/AttachMoney';
import DeliveryIcon from '@mui/icons-material/LocalShipping';

const faqData = [
  {
    question: 'How do I register for storage?',
    answer: 'Register online in less than 5 minutes without needing a credit card. Just provide your information and select your storage plan.',
    icon: <RegistrationIcon />
  },
  {
    question: 'Which universities does SummerStore provide service for?',
    answer: 'To check if we service your university, please begin the registration process on our website and select your school from the dropdown menu.',
    icon: <InfoIcon />
  },
  {
    question: 'What items can I store?',
    answer: 'You can store a wide range of items, including boxes, furniture, and other personal belongings. Our pricing plans accommodate different quantities and sizes of items.',
    icon: <StorageIcon />
  },
  {
    question: 'Are my items insured?',
    answer: 'Yes, your items are insured while in possession of SummerStore. We provide insurance coverage of up to $200 per item under all of our available plans, ensuring that your items are protected against damage or loss during the storage period.',
    icon: <InfoIcon />
  },
  {
    question: 'When will my items be delivered?',
    answer: 'Your items will be delivered back to campus at the beginning of the next semester, ensuring they are ready when you return.',
    icon: <DeliveryIcon />
  },
  {
    question: 'Where are my items stored?',
    answer: 'Your items are stored in climate-controlled and secure environments provided by our partners, with insurance coverage for added protection.',
    icon: <InfoIcon />
  },
  {
    question: 'What makes SummerStore different from other storage companies?',
    answer: 'SummerStore focuses on the affordability of our service by creating convenient pickup and dropoff locations directly positioned outside of residential halls for dropping off and collecting student belongings. This strategy minimizes operational costs, allowing us to offer transparent and cost-effective bundled pricing plans for students.',
    icon: <InfoIcon />
  }
];

const FAQ = () => {
  return (
    <div style={{width: {m: '80%', xs: '100%'}, display: "flex", flexDirection: "column"}}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
        Frequently Asked Questions
      </Typography>
      {faqData.map((faq, index) => (
        <Accordion key={index} sx={{ mb: 2, bgcolor: "transparent", 
        borderRadius: "10px",  }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              "&.MuiAccordionSummary-root": {
                backgroundColor: "transparent"
              },
              bgcolor: "transparent",
              padding: 2, 
              border: "2px solid",
              borderRadiusLeftTop: "10px", 
              borderBottom: "5px solid", 
              borderColor: "primary.main"
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              {faq.icon}
              <Typography sx={{ ml: 2, fontSize: {m: '1.25rem', xs: '0.9rem'}, fontWeight: 'bold' }}>{faq.question}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 3, backgroundColor: "primary.main", color: 'white' }}>
            <Typography sx={{ fontSize: '1.1rem' }}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;

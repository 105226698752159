import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TablePagination, CircularProgress, useTheme, Tooltip, Tabs, Tab, Button, Dialog, DialogActions,
  DialogContent, DialogTitle, TextField, MenuItem, Select
} from '@mui/material';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ContainerLocationEditor from '../components/ContainerLocationEditor';
import { collection, getDocs, doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase';

const UniversityViewContainers = () => {
  const navigate = useNavigate();
  const { universityID } = useParams();
  const [value, setValue] = useState(0);
  const [containers, setContainers] = useState([]);
  const [containerLocations, setContainerLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();

  // State for dialog management
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [newContainerData, setNewContainerData] = useState({
    containerLocationId: '',
    creationOrder: 0,
    spaceCapacity: 1000,
    spaceReserved: 0,
    universityId: universityID,
  });

  useEffect(() => {
    fetchContainers();
  }, [universityID]);

  const fetchContainers = async () => {
    setLoading(true);
    const containerLocationsRef = collection(db, `universities/${universityID}/containerLocations`);
    const containerLocationsSnapshot = await getDocs(containerLocationsRef);
    const locations = containerLocationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    let allContainers = [];
    for (const location of locations) {
      if (location.containerIDs) {
        for (let i = 0; i < location.containerIDs.length; i++) {
          const containerID = location.containerIDs[i];
          const containerDoc = await getDoc(doc(db, 'containers', containerID));
          if (containerDoc.exists()) {
            const customersSnapshot = await getDocs(collection(db, `containers/${containerID}/customers`));
            let actualSpace = 0, paidSpace = 0, registeredSpace = 0;
            
            customersSnapshot.docs.forEach(customerDoc => {
              const customerData = customerDoc.data();
              actualSpace += customerData.actualSpace || 0;
              paidSpace += customerData.paidSpace || 0;
              registeredSpace += customerData.registeredSpace || 0;
            });

            allContainers.push({
              id: containerDoc.id,
              ...containerDoc.data(),
              customerCount: customersSnapshot.size,
              actualSpace,
              paidSpace,
              registeredSpace,
              locationName: location.name, // Assuming 'name' is a field in container location
              creationOrder: i, // Creation order based on index
              totalContainers: location.containerIDs.length, // Total containers for the location
            });
          }
        }
      }
    }

    setContainerLocations(locations);
    setContainers(allContainers);
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const calculatePercentage = (used, total) => {
    return (used / total) * 100;
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Dialog open/close handlers
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle input change in the dialog
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContainerData({ ...newContainerData, [name]: value });
  };

  // Handle dropdown change for container location
  const handleLocationChange = (e) => {
    const selectedLocationId = e.target.value;
    const selectedLocation = containerLocations.find(location => location.id === selectedLocationId);
    const creationOrder = selectedLocation?.containerIDs?.length || 0;

    setNewContainerData({
      ...newContainerData,
      containerLocationId: selectedLocationId,
      creationOrder,
    });
  };

  // Save new container to Firestore
  const handleSave = async () => {
    setSaving(true); // Start the loading indicator
    try {
      const docRef = doc(collection(db, 'containers'));
      await setDoc(docRef, {
        ...newContainerData,
        id: docRef.id, // Auto-generate an ID
      });

      // Update the containerIDs array in the selected container location
      const containerLocationRef = doc(db, `universities/${universityID}/containerLocations/${newContainerData.containerLocationId}`);
      await updateDoc(containerLocationRef, {
        containerIDs: arrayUnion(docRef.id)
      });

      console.log('Container saved successfully!');

      // Refresh containers by fetching them again
      await fetchContainers();

      handleClose();
    } catch (error) {
      console.error('Error saving container:', error);
    } finally {
      setSaving(false); // Stop the loading indicator
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{ width: "auto", mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        University Containers
      </Typography>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Container Locations" />
          <Tab label="Containers" />
        </Tabs>
      </Paper>
      <Box sx={{ p: 0, my: 2 }}>
        {value === 0 && (
          <ContainerLocationEditor
            universityID={universityID}
            containerLocations={containerLocations}
            setContainerLocations={setContainerLocations}
          />
        )}
        {value === 1 && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Containers
              </Typography>
              <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Add Container
              </Button>
            </Box>
            <Paper sx={{ p: 2 }}>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Container Location</TableCell>
                        <TableCell>Creation Order</TableCell>
                        <TableCell>Serviced Customers</TableCell>
                        <TableCell>Capacity Information</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {containers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((container, index) => {
                        const actualPercentage = calculatePercentage(container.actualSpace, container.spaceCapacity);
                        const paidPercentage = calculatePercentage(container.paidSpace, container.spaceCapacity);
                        const registeredPercentage = calculatePercentage(container.registeredSpace, container.spaceCapacity);
                        const unreservedPercentage = 100 - (actualPercentage + paidPercentage + registeredPercentage);

                        return (
                          <TableRow key={container.id} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit' }}>
                            <TableCell>{container.locationName || 'Unknown'}</TableCell>
                            <TableCell>{`${container.creationOrder + 1}/${container.totalContainers}`}</TableCell>
                            <TableCell>{container.customerCount}</TableCell>
                            <TableCell>
                              <Box sx={{ width: '100%', position: 'relative', height: '20px', borderRadius: '5px', overflow: 'hidden', display: 'flex' }}>
                                <Tooltip title={`Actual Used: ${actualPercentage.toFixed(2)}% (${container.actualSpace} cubic ft.)`}>
                                  <Box sx={{ width: `${actualPercentage}%`, backgroundColor: '#4caf50', height: '100%' }}></Box>
                                </Tooltip>
                                <Tooltip title={`Paid Reserved: ${paidPercentage.toFixed(2)}% (${container.paidSpace} cubic ft.)`}>
                                  <Box sx={{ width: `${paidPercentage}%`, backgroundColor: '#8bc34a', height: '100%' }}></Box>
                                </Tooltip>
                                <Tooltip title={`Anticipated: ${registeredPercentage.toFixed(2)}% (${container.registeredSpace} cubic ft.)`}>
                                  <Box sx={{ width: `${registeredPercentage}%`, backgroundColor: '#ffeb3b', height: '100%' }}></Box>
                                </Tooltip>
                                <Tooltip title={`Unreserved: ${unreservedPercentage.toFixed(2)}%`}>
                                  <Box sx={{ width: `${unreservedPercentage}%`, backgroundColor: '#e0e0e0', height: '100%' }}></Box>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Link to={`/dashboard/containers/container/${container.id}`}>View Contents</Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={containers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </TableContainer>
              )}
            </Paper>
          </Box>
        )}
      </Box>

      {/* Dialog for Adding New Container */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Container</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            name="containerLocationId"
            value={newContainerData.containerLocationId}
            onChange={handleLocationChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Container Location
            </MenuItem>
            {containerLocations.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            name="creationOrder"
            label="Creation Order"
            type="number"
            fullWidth
            value={newContainerData.creationOrder}
            onChange={handleInputChange}
            disabled
          />
          <TextField
            margin="dense"
            name="spaceCapacity"
            label="Space Capacity"
            type="number"
            fullWidth
            value={newContainerData.spaceCapacity}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="spaceReserved"
            label="Space Reserved"
            type="number"
            fullWidth
            value={newContainerData.spaceReserved}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="universityId"
            label="University ID"
            type="text"
            fullWidth
            value={newContainerData.universityId}
            onChange={handleInputChange}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving}>
            {saving ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UniversityViewContainers;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, Paper, TextField, Button, CircularProgress } from '@mui/material';
import { AccountCircle, Security, Payment, Notifications } from '@mui/icons-material';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../contexts/NotificationContext';
import { db } from '../firebase';

const AccountSettings = () => {
  const navigate = useNavigate();
  const functions = getFunctions();
  const { addNotification } = useNotification();
  const [value, setValue] = useState(0);
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProfileData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePasswordChange = (event) => {
    setPasswordData({ ...passwordData, [event.target.name]: event.target.value });
  };

  const handleProfileChange = (event) => {
    setProfileData({ ...profileData, [event.target.name]: event.target.value });
  };

  const saveProfileChanges = async () => {
    setLoading(true);
    setError('');

    try {
      const updateProfileFunction = httpsCallable(functions, 'updateUserProfile');
      const result = await updateProfileFunction({ 
        uid: user.uid, 
        profileData 
      });

      if (result.data.success) {
        setError('Profile updated successfully.');
      } else {
        setError('Failed to update profile. Please try again.');
      }
    } catch (error) {
      setError('Failed to update profile. Please try again.');
      console.error('Error updating profile through cloud function:', error);
    } finally {
      setLoading(false);
    }
  };

  const savePasswordChange = async () => {
    setLoading(true);
    setError('');

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError('New passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      if (user) {
        // Reauthenticate the user with the current password
        const credential = EmailAuthProvider.credential(user.email, passwordData.currentPassword);
        await reauthenticateWithCredential(user, credential);
        
        // Update the password
        await updatePassword(user, passwordData.newPassword);
        setError('');
        setPasswordData({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
        addNotification({ status: 'success', message: 'Password updated successfully!' });
        navigate('/dashboard');
      }
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError('The current password is incorrect.');
      } else if (error.code === 'auth/weak-password') {
        setError('Password should be at least 6 characters.');
      } else if (error.code === 'auth/requires-recent-login') {
        setError('Please log in again and try updating the password.');
      } else {
        setError('Failed to update password. Please try again.');
      }
      console.error('Error updating password:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab icon={<AccountCircle />} label="Profile" />
          <Tab icon={<Security />} label="Security" />
        </Tabs>
      </Paper>
      <Paper sx={{ p: 3, mt: 2 }}>
        {value === 0 && (
          <Box>
            <Typography variant="h6" gutterBottom>Profile Settings</Typography>
            <TextField label="First Name" name="firstName" value={profileData.firstName} onChange={handleProfileChange} fullWidth margin="normal" />
            <TextField label="Email Address" name="email" value={profileData.email} onChange={handleProfileChange} fullWidth margin="normal" />
            <TextField label="Phone Number" name="phoneNumber" value={profileData.phoneNumber} onChange={handleProfileChange} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={saveProfileChanges} sx={{ mt: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
            {error && <Typography color={error === 'Profile updated successfully.' ? 'success' : 'error'} variant="body2" sx={{ mt: 1 }}>{error}</Typography>}
          </Box>
        )}
        {value === 1 && (
          <Box>
            <Typography variant="h6" gutterBottom>Security Settings</Typography>
            <TextField label="Current Password" name="currentPassword" type="password" value={passwordData.currentPassword} onChange={handlePasswordChange} fullWidth margin="normal" />
            <TextField label="New Password" name="newPassword" type="password" value={passwordData.newPassword} onChange={handlePasswordChange} fullWidth margin="normal" />
            <TextField label="Confirm New Password" name="confirmPassword" type="password" value={passwordData.confirmPassword} onChange={handlePasswordChange} fullWidth margin="normal" />
            {error && <Typography color={error === 'Password updated successfully.' ? 'success' : 'error'} variant="body2" sx={{ mt: 1 }}>{error}</Typography>}
            <Button variant="contained" color="primary" onClick={savePasswordChange} sx={{ mt: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Change Password'}
            </Button>
          </Box>
        )}
        {/* Payment and Notifications tabs would be handled similarly */}
      </Paper>
    </Box>
  );
};

export default AccountSettings;

import React from 'react';
import { Box, Typography, Grid, Paper, Tooltip, Link, Button } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from '@mui/lab';
import {
  School,
  ShoppingCart,
  Receipt,
  Inventory2,
  UploadFile,
  BeachAccess,
  CheckCircle,
  HelpOutline,
  AccountCircle,
  Storage,
  Event,
  CalendarToday,
  Inventory,
  Support,
  ReceiptLong,
  Settings,
} from '@mui/icons-material';
import NotificationsCarousel from '../components/NotificationsCarousel';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Dashboard = () => {
  const { userClaims, currentUser } = useAuth();
  const navigate = useNavigate();

  const timelineSteps = [
    { label: 'Registration Deadline', icon: <School />, date: '05/01/2024', tooltip: 'Complete your registration' },
    { label: 'Supplies Distribution', icon: <ShoppingCart />, date: '05/02/2024', tooltip: 'Pickup supplies' },
    { label: 'Item Reporting', icon: <Inventory2 />, date: '05/05/2024', tooltip: 'Report items via "My Items" before the deadline' },
    { label: 'Item Dropoffs', icon: <Inventory2 />, date: '05/05/2024 - 05/09/2024', tooltip: 'Drop off your items' },
    { label: 'Summer', icon: <BeachAccess />, date: '05/09/2024 - 08/13/2024', tooltip: 'Enjoy your summer break' },
    { label: 'Item Pickups', icon: <CheckCircle />, date: '08/13/2024 - 08/25/2024', tooltip: 'Pickup your items' },
  ];

  const currentStep = 'Summer';
  const currentStepIndex = timelineSteps.findIndex((step) => step.label === currentStep);

  const firstName = userClaims.firstName || 'User';
  const profileInitial = firstName.charAt(0);
  const fullName = `${firstName} ${userClaims.lastName || ''}`.trim();
  const email = currentUser?.email || '';

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Welcome, {firstName}!
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
            Account Details
          </Typography>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#007bff',
                  color: 'white',
                  flexShrink: 0,
                  height: '50px',
                  width: '50px',
                  mr: 2,
                }}
              >
                <Typography variant="h5" component="div" color="white">
                  {profileInitial}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">{fullName}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {email}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1">
                  <strong>Storage Plan:</strong> Legacy (Spring 2024)
                </Typography>
                <Link href="dashboard/plan" variant="body2" sx={{ textAlign: 'right' }}>
                  Change Plan
                </Link>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1">
                  <strong>Pickup Date:</strong> --/--/----
                </Typography>
                <Link href="dashboard/appointments" variant="body2" sx={{ textAlign: 'right' }}>
                  Edit
                </Link>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1">
                  <strong>Dropoff Date:</strong> --/--/----
                </Typography>
                <Link href="dashboard/appointments" variant="body2" sx={{ textAlign: 'right' }}>
                  Edit
                </Link>
              </Box>
            </Box>
          </Paper>
          <Typography variant="subtitle2" gutterBottom sx={{ mt: 2, color: 'text.secondary', textTransform: 'uppercase' }}>
            Quick Access
          </Typography>
          <Box>
            <Button
              variant="contained"
              fullWidth
              startIcon={<Inventory />}
              onClick={() => navigate('items')}
            >
              My Items
            </Button>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Settings />}
              sx={{ mt: 1 }}
              onClick={() => navigate('settings')}
            >
              Account Settings
            </Button>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<CalendarToday />}
              sx={{ mt: 1 }}
              onClick={() => navigate('appointments')}
            >
              Appointments
            </Button>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Support />}
              sx={{ mt: 1 }}
              onClick={() => navigate('support')}
            >
              Support
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase', mb: 2 }}>
            Service Timeline
          </Typography>
          <Paper sx={{ p: 2, pt: 1 }}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {timelineSteps.map((step, index) => (
                <TimelineItem key={step.label}>
                  <TimelineSeparator>
                    <TimelineDot color={index <= currentStepIndex ? 'primary' : 'grey'}>
                      {step.icon}
                    </TimelineDot>
                    {index < timelineSteps.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Typography variant="body1" color={index <= currentStepIndex ? 'primary' : 'textSecondary'}>
                        {step.label}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {step.date}
                      </Typography>
                    </Box>
                    <Tooltip title={step.tooltip}>
                      <HelpOutline sx={{ color: 'grey.500' }} />
                    </Tooltip>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;

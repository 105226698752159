import React, { useState } from 'react';
import { Box, Typography, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const AmbassadorInformation = ({ ambassadorInfo, setAmbassadorInfo }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentAmbassadorInfo, setCurrentAmbassadorInfo] = useState(ambassadorInfo);

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleSave = () => {
    setAmbassadorInfo(currentAmbassadorInfo);
    handleDialogClose();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Ambassador Information
        </Typography>
        <Button variant="contained" color="primary" onClick={handleEditClick}>
          Edit
        </Button>
      </Box>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="body1">Ambassador Name: {ambassadorInfo.name}</Typography>
        <Typography variant="body1">Contact: {ambassadorInfo.contact}</Typography>
      </Paper>

      <Dialog open={editDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Ambassador Information</DialogTitle>
        <DialogContent>
          <Box component="form">
            <TextField
              label="Ambassador Name"
              fullWidth
              margin="normal"
              value={currentAmbassadorInfo.name}
              onChange={(e) => setCurrentAmbassadorInfo({ ...currentAmbassadorInfo, name: e.target.value })}
            />
            <TextField
              label="Contact"
              fullWidth
              margin="normal"
              value={currentAmbassadorInfo.contact}
              onChange={(e) => setCurrentAmbassadorInfo({ ...currentAmbassadorInfo, contact: e.target.value })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AmbassadorInformation;

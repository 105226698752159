import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import { db } from '../firebase';  // Import the Firebase setup
import { collection, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const getStatus = (parking) => {
  if (!parking.meterBagsNeeded) {
    return 'none';
  }
  if (parking.meterBags.some((bag) => !bag.requested)) {
    return 'alert';
  }
  if (parking.meterBags.some((bag) => bag.requested && !bag.reserved)) {
    return 'warning';
  }
  return 'none';
};

const getStatusColor = (status) => {
  switch (status) {
    case 'warning':
      return '#FB9D04';
    case 'alert':
      return 'red';
    default:
      return 'primary.main';
  }
};

const getStatusMessage = (status) => {
  switch (status) {
    case 'warning':
      return 'Bags requested but not reserved.';
    case 'alert':
      return 'Bags still unrequested.';
    default:
      return 'N/A';
  }
};

const countWarningsAndAlerts = (locations) => {
  let warnings = 0;
  let alerts = 0;

  locations.forEach((location) => {
    const status = getStatus(location.parking);
    if (status === 'warning') {
      warnings++;
    }
    if (status === 'alert') {
      alerts++;
    }
  });

  return { warnings, alerts };
};

const ContainerLocationEditor = ({ universityID, containerLocations, setContainerLocations }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentEditLocation, setCurrentEditLocation] = useState(null);
  const [newLocation, setNewLocation] = useState({
    name: '',
    addresses: '',
    notes: '',
    parking: {
      meterBagsNeeded: false,
      meterBags: [],
    },
    servicedEntryways: []
  });

  const { warnings, alerts } = countWarningsAndAlerts(containerLocations);

  const handleEditClick = (location) => {
    const locationWithDefaults = {
      ...location,
      parking: {
        meterBagsNeeded: false,
        meterBags: [],
        ...location.parking,
      },
      servicedEntryways: location.servicedEntryways || []
    };
    setCurrentEditLocation(locationWithDefaults);
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setCurrentEditLocation(null);
  };

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
    setNewLocation({
      name: '',
      addresses: '',
      notes: '',
      parking: {
        meterBagsNeeded: false,
        meterBags: [],
      },
      servicedEntryways: []
    });
  };

  const handleAddMeterBag = () => {
    setCurrentEditLocation((prev) => ({
      ...prev,
      parking: {
        ...prev.parking,
        meterBags: [...prev.parking.meterBags, { id: '', requested: false, reserved: false }],
      },
    }));
  };

  const handleNewMeterBag = () => {
    setNewLocation((prev) => ({
      ...prev,
      parking: {
        ...prev.parking,
        meterBags: [...prev.parking.meterBags, { id: '', requested: false, reserved: false }],
      },
    }));
  };

  const handleMeterBagChange = (index, field, value) => {
    setCurrentEditLocation((prev) => {
      const updatedMeterBags = [...prev.parking.meterBags];
      updatedMeterBags[index][field] = value;
      return {
        ...prev,
        parking: {
          ...prev.parking,
          meterBags: updatedMeterBags,
        },
      };
    });
  };

  const handleNewMeterBagChange = (index, field, value) => {
    setNewLocation((prev) => {
      const updatedMeterBags = [...prev.parking.meterBags];
      updatedMeterBags[index][field] = value;
      return {
        ...prev,
        parking: {
          ...prev.parking,
          meterBags: updatedMeterBags,
        },
      };
    });
  };

  const handleMeterBagDelete = (index) => {
    setCurrentEditLocation((prev) => {
      const updatedMeterBags = [...prev.parking.meterBags];
      updatedMeterBags.splice(index, 1);
      return {
        ...prev,
        parking: {
          ...prev.parking,
          meterBags: updatedMeterBags,
        },
      };
    });
  };

  const handleNewMeterBagDelete = (index) => {
    setNewLocation((prev) => {
      const updatedMeterBags = [...prev.parking.meterBags];
      updatedMeterBags.splice(index, 1);
      return {
        ...prev,
        parking: {
          ...prev.parking,
          meterBags: updatedMeterBags,
        },
      };
    });
  };

  const handleServicedEntrywaysChange = (index, value) => {
    setCurrentEditLocation((prev) => {
      const updatedEntryways = [...prev.servicedEntryways];
      updatedEntryways[index] = value;
      return {
        ...prev,
        servicedEntryways: updatedEntryways,
      };
    });
  };

  const handleNewServicedEntrywaysChange = (index, value) => {
    setNewLocation((prev) => {
      const updatedEntryways = [...prev.servicedEntryways];
      updatedEntryways[index] = value;
      return {
        ...prev,
        servicedEntryways: updatedEntryways,
      };
    });
  };

  const handleAddServicedEntryway = () => {
    setCurrentEditLocation((prev) => ({
      ...prev,
      servicedEntryways: [...prev.servicedEntryways, ''],
    }));
  };

  const handleNewAddServicedEntryway = () => {
    setNewLocation((prev) => ({
      ...prev,
      servicedEntryways: [...prev.servicedEntryways, ''],
    }));
  };

  const handleDeleteServicedEntryway = (index) => {
    setCurrentEditLocation((prev) => {
      const updatedEntryways = [...prev.servicedEntryways];
      updatedEntryways.splice(index, 1);
      return {
        ...prev,
        servicedEntryways: updatedEntryways,
      };
    });
  };

  const handleNewDeleteServicedEntryway = (index) => {
    setNewLocation((prev) => {
      const updatedEntryways = [...prev.servicedEntryways];
      updatedEntryways.splice(index, 1);
      return {
        ...prev,
        servicedEntryways: updatedEntryways,
      };
    });
  };

  const handleSave = async () => {
    if (currentEditLocation) {
      const locationRef = doc(db, `universities/${universityID}/containerLocations`, currentEditLocation.id);
      await updateDoc(locationRef, currentEditLocation);
      setContainerLocations((prev) =>
        prev.map((location) =>
          location.id === currentEditLocation.id ? currentEditLocation : location
        )
      );
    }
    handleDialogClose();
  };

  const handleAddSave = async () => {
    const newLocationData = {
      ...newLocation,
      addresses: newLocation.addresses.split(',').map(address => address.trim())
    };
    const containerCollectionRef = collection(db, `universities/${universityID}/containerLocations`);
    const docRef = await addDoc(containerCollectionRef, newLocationData);

    setContainerLocations((prev) => [...prev, { id: docRef.id, ...newLocationData }]);
    handleAddDialogClose();
  };

  const handleDeleteLocation = async (locationID) => {
    await deleteDoc(doc(db, `universities/${universityID}/containerLocations`, locationID));
    setContainerLocations((prev) => prev.filter((location) => location.id !== locationID));
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="h6" gutterBottom>
            Container Locations
          </Typography>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Warnings: {warnings} | Alerts: {alerts}
          </Typography>
        </Box>
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setAddDialogOpen(true)}>
          Add Container Location
        </Button>
      </Box>
      {containerLocations.map((location, index) => {
        const status = getStatus(location.parking);
        return (
          <Paper sx={{ p: 2, mb: 2, borderLeft: `4px solid`, borderColor: getStatusColor(status) }} key={index}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">{location.name}</Typography>
              <Box>
                <IconButton onClick={() => handleEditClick(location)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDeleteLocation(location.id)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ color: getStatusColor(status), fontWeight: 'bold' }}>
              Status: {getStatusMessage(status)}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body1">Addresses:</Typography>
                {location.addresses.map((address, idx) => (
                  <Typography key={idx} variant="body2">
                    {address}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body1">Parking:</Typography>
                <Typography variant="body2">
                  Meter Bags Needed: {location.parking.meterBagsNeeded ? 'Yes' : 'No'}
                </Typography>
                {location.parking.meterBagsNeeded && (
                  <>
                    <Typography variant="body2">Meter Bags:</Typography>
                    {location.parking.meterBags.map((bag, idx) => (
                      <Typography key={idx} variant="body2">
                        {bag.id}
                      </Typography>
                    ))}
                  </>
                )}
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body1">Notes: {location.notes}</Typography>
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body1">Serviced Entryway(s):</Typography>
              {location.servicedEntryways && location.servicedEntryways.length > 0 ? (
                <Typography variant="body2">
                  {location.servicedEntryways.join(', ')}
                </Typography>
              ) : (
                <Typography variant="body2">No Serviced Entryways</Typography>
              )}
            </Box>
          </Paper>
        );
      })}

      <Dialog open={editDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Container Location</DialogTitle>
        <DialogContent>
          {currentEditLocation && (
            <Box component="form">
              <TextField
                label="Location Name"
                fullWidth
                margin="normal"
                value={currentEditLocation.name}
                onChange={(e) => setCurrentEditLocation((prev) => ({ ...prev, name: e.target.value }))}
              />
              <TextField
                label="Addresses"
                fullWidth
                margin="normal"
                value={currentEditLocation.addresses.join(', ')}
                onChange={(e) => setCurrentEditLocation((prev) => ({ ...prev, addresses: e.target.value.split(',').map(address => address.trim()) }))}
              />
              <TextField
                label="Notes"
                fullWidth
                margin="normal"
                value={currentEditLocation.notes}
                onChange={(e) => setCurrentEditLocation((prev) => ({ ...prev, notes: e.target.value }))}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentEditLocation.parking.meterBagsNeeded}
                    onChange={(e) =>
                      setCurrentEditLocation((prev) => ({
                        ...prev,
                        parking: { ...prev.parking, meterBagsNeeded: e.target.checked },
                      }))
                    }
                  />
                }
                label="Meter Bags Needed"
              />
              {currentEditLocation.parking.meterBagsNeeded && (
                <Box>
                  <Button variant="outlined" onClick={handleAddMeterBag} sx={{ mb: 2 }}>
                    Add Meter Bag
                  </Button>
                  <List>
                    {currentEditLocation.parking.meterBags.map((bag, idx) => (
                      <ListItem key={idx}>
                        <TextField
                          label="Meter Bag ID"
                          value={bag.id}
                          onChange={(e) => handleMeterBagChange(idx, 'id', e.target.value)}
                          sx={{ mr: 2 }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bag.requested}
                              onChange={(e) => handleMeterBagChange(idx, 'requested', e.target.checked)}
                            />
                          }
                          label="Requested"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bag.reserved}
                              onChange={(e) => handleMeterBagChange(idx, 'reserved', e.target.checked)}
                            />
                          }
                          label="Reserved"
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" onClick={() => handleMeterBagDelete(idx)}>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
              <Typography variant="h6" gutterBottom>Serviced Entryways</Typography>
              
              {currentEditLocation.servicedEntryways.length > 0 ? (
                <List>
                  {currentEditLocation.servicedEntryways.map((entryway, idx) => (
                    <ListItem key={idx}>
                      <TextField
                        label="Entryway Name"
                        value={entryway}
                        onChange={(e) => handleServicedEntrywaysChange(idx, e.target.value)}
                        sx={{ mr: 2 }}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleDeleteServicedEntryway(idx)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2">No Serviced Entryways</Typography>
              )}
              <Button variant="outlined" onClick={handleAddServicedEntryway} sx={{ mb: 2 }}>
                Add Serviced Entryway
              </Button>

            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
        <DialogTitle>Add Container Location</DialogTitle>
        <DialogContent>
          <Box component="form">
            <TextField
              label="Location Name"
              fullWidth
              margin="normal"
              value={newLocation.name}
              onChange={(e) => setNewLocation((prev) => ({ ...prev, name: e.target.value }))}
            />
            <TextField
              label="Addresses"
              fullWidth
              margin="normal"
              value={newLocation.addresses}
              onChange={(e) => setNewLocation((prev) => ({ ...prev, addresses: e.target.value }))}
            />
            <TextField
              label="Notes"
              fullWidth
              margin="normal"
              value={newLocation.notes}
              onChange={(e) => setNewLocation((prev) => ({ ...prev, notes: e.target.value }))}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newLocation.parking.meterBagsNeeded}
                  onChange={(e) =>
                    setNewLocation((prev) => ({
                      ...prev,
                      parking: { ...prev.parking, meterBagsNeeded: e.target.checked },
                    }))
                  }
                />
              }
              label="Meter Bags Needed"
            />
            {newLocation.parking.meterBagsNeeded && (
              <Box>
                <Button variant="outlined" onClick={handleNewMeterBag} sx={{ mb: 2 }}>
                  Add Meter Bag
                </Button>
                <List>
                  {newLocation.parking.meterBags.map((bag, idx) => (
                    <ListItem key={idx}>
                      <TextField
                        label="Meter Bag ID"
                        value={bag.id}
                        onChange={(e) => handleNewMeterBagChange(idx, 'id', e.target.value)}
                        sx={{ mr: 2 }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={bag.requested}
                            onChange={(e) => handleNewMeterBagChange(idx, 'requested', e.target.checked)}
                          />
                        }
                        label="Requested"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={bag.reserved}
                            onChange={(e) => handleNewMeterBagChange(idx, 'reserved', e.target.checked)}
                          />
                        }
                        label="Reserved"
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleNewMeterBagDelete(idx)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            <Typography variant="h6" gutterBottom>Serviced Entryways</Typography>
            <Button variant="outlined" onClick={handleNewAddServicedEntryway} sx={{ mb: 2 }}>
              Add Serviced Entryway
            </Button>
            {newLocation.servicedEntryways.length > 0 ? (
              <List>
                {newLocation.servicedEntryways.map((entryway, idx) => (
                  <ListItem key={idx}>
                    <TextField
                      label="Entryway Name"
                      value={entryway}
                      onChange={(e) => handleNewServicedEntrywaysChange(idx, e.target.value)}
                      sx={{ mr: 2 }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => handleNewDeleteServicedEntryway(idx)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2">No Serviced Entryways</Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContainerLocationEditor;

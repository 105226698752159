// src/pages/SalesDetail.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, TextField, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const salesData = [
  { id: 1, customerName: 'John Doe', transactionId: 'TX12345', description: 'Storage for winter clothes', university: 'Yale University', amount: '$200', date: '2023-07-01' },
  { id: 2, customerName: 'Jane Smith', transactionId: 'TX12346', description: 'Storage for books and personal items', university: 'Harvard University', amount: '$150', date: '2023-07-02' },
  // Add more sample data as needed
];

const SalesDetail = () => {
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const [sale, setSale] = useState(null);

  useEffect(() => {
    const saleDetail = salesData.find((sale) => sale.transactionId === transactionId);
    setSale(saleDetail);
  }, [transactionId]);

  const handleSave = () => {
    // Logic to save the sale
    navigate('/dashboard/sales-dashboard');
  };

  if (!sale) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Sale Details
      </Typography>
      <Paper sx={{ p: 3 }}>
        <TextField margin="dense" label="Customer Name" fullWidth defaultValue={sale.customerName} />
        <TextField margin="dense" label="Transaction ID" fullWidth defaultValue={sale.transactionId} />
        <TextField margin="dense" label="Description" fullWidth defaultValue={sale.description} />
        <TextField margin="dense" label="University" fullWidth defaultValue={sale.university} />
        <TextField margin="dense" label="Amount" fullWidth defaultValue={sale.amount} />
        <TextField margin="dense" label="Date" type="date" fullWidth defaultValue={sale.date} />
        <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
          Save Changes
        </Button>
        <Button variant="outlined" onClick={() => navigate('/dashboard/sales-dashboard')} sx={{ mt: 2, ml: 2 }}>
          Back
        </Button>
      </Paper>
    </Box>
  );
};

export default SalesDetail;

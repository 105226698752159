import React, { useState, useEffect } from 'react';
import { Box, Typography, Button} from '@mui/material';
import { useNavigate, useParams} from 'react-router-dom';
import ContainerViewer from '../components/ContainerViewer'; // Import the Grid component

const ContainerViewerPage = () => {

  const { containerID } = useParams();
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{width: "auto", mb: 2}}>
          Back
      </Button>
      <Typography variant="h4" gutterBottom>
        Container Viewer
      </Typography>
      <ContainerViewer containerId={containerID}></ContainerViewer>
    </Box>
  );
};

export default ContainerViewerPage;

import React, { useState, useEffect, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { TransformControls, Edges, OrbitControls } from '@react-three/drei';
import { getDocs, collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Grid from './Grid'; // Import the Grid component
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Slider, Button } from '@mui/material';
import PhysicsObject from './PhysicsObject';
import { Bin, Item, Packer } from '@owens3364/3d-bin-packing';
import * as THREE from 'three';

const CameraSetup = () => {
  const { camera } = useThree();
  camera.position.set(190, 100, 200);
  camera.lookAt(0, 0, 60);
  return null;
};


const generateRandomItems = (containerWidth, containerHeight, containerLength, scale, itemCount) => {
  const items = [];
  const packer = new Packer();

  // Create a bin representing the container
  const bin = new Bin('Container', containerWidth/100000, containerHeight/100000, containerLength/100000);
  packer.addBin(bin);

  // Generate random items and add them to the packer
  for (let i = 0; i < itemCount * 12 ; i++) {
    const length = 18/100000;
    const height = 24/100000;
    const width = 18/100000;

    const item = new Item(`Item ${i}`, width, height, length);
    packer.addItem(item);
  }

  // Pack items into the bin
  packer.pack();

  console.log("Bin items: ", bin.items);

  // Process packed items
  bin.items.forEach(packedItem => {
    const position = [
      (packedItem.position[0] / scale) + (packedItem.width / (2 * scale)),
      (packedItem.position[1] / scale) + (packedItem.height / (2 * scale)),
      (packedItem.position[2] / scale) + (packedItem.depth / (2 * scale))
    ];

    items.push({
      id: packedItem.name,
      position,
      actualPosition: [packedItem.position.x, packedItem.position.y, packedItem.position.z],
      dimensions: [packedItem.width / scale, packedItem.height / scale, packedItem.depth / scale],
      color: 'cyan',
      ownerUid: 'random',
      ownerName: 'N/A',
      itemName: packedItem.name,
      itemType: 'random',
    });
  });

  console.log("items: ", items);
  return items;
};


const ContainerViewer = ({ containerId }) => {
  const scale = 1; // Increase to make everything smaller
  const containerWidth = 190;
  const containerLength = 91;
  const containerHeight = 83;
  const [cubes, setCubes] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [slicePosition, setSlicePosition] = useState(containerLength); // Slider value
  const [shownCubes, setShownCubes] = useState([]);
  const [hiddenCubes, setHiddenCubes] = useState([]);
  const [slicerTransparency, setSlicerTransparency] = useState(0); // Slicer transparency state
  const transformRef = useRef();

  const fetchContainerItems = async () => {
    if (!containerId) {
      console.log("No container ID found");
      return;
    }

    const customersCollection = collection(db, `containers/${containerId}/customers`);
    const customersSnapshot = await getDocs(customersCollection);
    const items = [];

    for (const customerDoc of customersSnapshot.docs) {
      const customerId = customerDoc.id;

      console.log("customers: ", customerId);
      const customerItemsCollection = collection(db, `containers/${containerId}/customers/${customerId}/items`);
      const customerItemsSnapshot = await getDocs(customerItemsCollection);

      for (const itemDoc of customerItemsSnapshot.docs) {
        const itemData = itemDoc.data();
        const { dimensions, location } = itemData;

        dimensions.length = dimensions.length / scale;
        dimensions.height = dimensions.height / scale;
        dimensions.width = dimensions.width / scale;

        const ownerDocRef = doc(db, `users/${customerId}`);
        const ownerDoc = await getDoc(ownerDocRef);
        const ownerData = ownerDoc.exists() ? ownerDoc.data() : { firstName: '', lastName: '' };

        items.push({
          id: itemDoc.id,
          position: [
            (location.x / scale) + (dimensions.length / 2),
            (location.y / scale) + (dimensions.height / 2),
            (location.z / scale) + (dimensions.width / 2)
          ],
          actualPosition: [
            location.x,
            location.y,
            location.z
          ],
          dimensions: [dimensions.length, dimensions.height, dimensions.width],
          color: 'cyan',
          ownerUid: customerId,
          ownerName: `${ownerData.firstName.charAt(0)}. ${ownerData.lastName}`,
          itemName: itemData.name,
          itemType: itemData.type,
        });
      }
    }

    setCubes(items);
    filterCubes(items, slicePosition);
  };

  const filterCubes = (cubes, position) => {
    const shown = cubes.filter(cube => cube.position[0] <= position);
    const hidden = cubes.filter(cube => cube.position[0] > position);

    setShownCubes(shown);
    setHiddenCubes(hidden);

    console.log(`Slice Position: ${position}`);
    console.log('Shown Cubes:', shown);
    console.log('Hidden Cubes:', hidden);
  };

  useEffect(() => {
    fetchContainerItems();
  }, [containerId]);

  const handlePointerDown = async (e, itemData) => {
    if (!itemData) {
      console.error("itemData is undefined");
      return;
    }

    setSelectedObject({
      object: e.object,
      itemData: itemData
    });

    try {
      const ownerDocRef = doc(db, `users/${itemData.ownerUid}`);
      const ownerDoc = await getDoc(ownerDocRef);
      if (ownerDoc.exists()) {
        setOwnerInfo(ownerDoc.data());
      } else {
        setOwnerInfo(null);
      }
    } catch (error) {
      console.error("Error fetching owner info:", error);
    }
  };

  const handleTransformStart = () => {
    // No need to disable orbit controls
  };

  const handleTransformEnd = () => {
    // No need to enable orbit controls
  };

  const handleItemClick = (item) => {
    setSelectedObject({
      object: item,
      itemData: item
    });
  };

  const handleSliderChange = (event, newValue) => {
    setSlicePosition(newValue);
    filterCubes(cubes, newValue);
    setSlicerTransparency(0.5); // Set transparency when slider is moving
  };

  const handleSliderChangeCommitted = () => {
    setSlicerTransparency(0); // Reset transparency when slider stops moving
  };

  const addRandomItems = () => {
    const randomItems = generateRandomItems(containerWidth, containerHeight, containerLength, scale, 10);
    setCubes(prevCubes => [...prevCubes, ...randomItems]);
    filterCubes([...cubes, ...randomItems], slicePosition);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Box sx={{ position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)', background: '#fff', padding: '5px', zIndex: 1 }}>
          <Typography variant="h6">3D Item Viewer</Typography>
        </Box>
        
        <Box sx={{ flex: 2, position: 'relative', margin: '10px' }}>
          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>Editor</Typography>
          
          <Box sx={{ position: 'relative', border: '1px solid #ccc', height: "80vh" }}>
            <Canvas sx={{ width: '100%', height: '80vh' }}>
              <CameraSetup />
              <ambientLight intensity={0.5} />
              <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
              <pointLight position={[-10, -10, -10]} />
              <Grid gridLength={containerLength} gridWidth={containerWidth} scale={scale} orientation="xz" />
              <Grid gridLength={containerLength} gridWidth={containerHeight} scale={scale} orientation="xy" />
              <Grid gridLength={containerWidth} gridWidth={containerHeight} scale={scale} orientation="yz" />

              {shownCubes.map((cube, index) => (
                <PhysicsObject
                  key={index}
                  position={cube.position}
                  dimensions={cube.dimensions}
                  color={cube.color}
                  onPointerDown={handlePointerDown}
                  itemData={cube}
                />
              ))}
              {selectedObject && (
                <TransformControls
                  ref={transformRef}
                  object={selectedObject.object}
                  onDragStart={handleTransformStart}
                  onDragEnd={handleTransformEnd}
                />
              )}
              {/* Adding the slicing plane */}
              <mesh position={[slicePosition / scale, containerHeight / 2, containerWidth / 2]} rotation={[0, Math.PI / 2, Math.PI / 2]}>
                <planeGeometry args={[containerHeight, containerWidth]} />
                <meshBasicMaterial color="blue" opacity={slicerTransparency} transparent />
              </mesh>
            </Canvas>
          </Box>
        </Box>
        
        <Box sx={{ flex: 1,  margin: '10px', position: 'relative' }}>
          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>EXPLORER</Typography>
          <TableContainer component={Paper} sx={{p:1}}>
            
          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase', p: 1  }}>VIEW SLICER</Typography>
            <Box sx={{ width: '100%',  px:2 }}>
              <Slider
                value={slicePosition}
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChangeCommitted}
                min={0} 
                max={containerLength + 1}
                step={1}
                valueLabelDisplay="auto"
                aria-labelledby="slice-slider"
              />
            </Box>

          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase', p: 1 }}>ITEMS IN VIEW</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox />
                    </TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shownCubes.map((cube, index) => (
                    <TableRow key={index} onClick={() => handleItemClick(cube)} hover>
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                      <TableCell>{cube.ownerName}</TableCell>
                      <TableCell>{cube.itemType}</TableCell>
                      <TableCell>{cube.itemName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          
          <Typography variant="subtitle2" gutterBottom sx={{ mt: 2, color: 'text.secondary', textTransform: 'uppercase' }}>Properties</Typography>
          <Paper sx={{ p: 2 }}>
            {selectedObject && selectedObject.itemData ? (
              <div>
                <Typography variant="subtitle2" gutterBottom sx={{  color: 'text.secondary', textTransform: 'uppercase' }}>Item Details</Typography>
                <Typography variant="body1"><strong>Item Name:</strong> {selectedObject.itemData.itemName}</Typography>
                <Typography variant="body1"><strong>Item Type:</strong> {selectedObject.itemData.itemType}</Typography>
                <Typography variant="body1"><strong>Item ID:</strong> {selectedObject.itemData.id}</Typography>
                <Typography variant="body1"><strong>Item Position:</strong> {`[${selectedObject.itemData.actualPosition[0]}, ${selectedObject.itemData.actualPosition[1]}, ${selectedObject.itemData.actualPosition[2]}]`}</Typography>
                <Typography variant="subtitle2" gutterBottom sx={{ mt: 2, color: 'text.secondary', textTransform: 'uppercase' }}>Owner Details</Typography>
                {ownerInfo ? (
                  <Box>
                    <Typography variant="body1"><strong>Owner:</strong> {ownerInfo.firstName} {ownerInfo.lastName}</Typography>
                    <Typography variant="body1"><strong>Email:</strong> {ownerInfo.email}</Typography>
                  </Box>
                ) : (
                  <Typography variant="body1">Loading owner info...</Typography>
                )}
              </div>
            ) : (
              <Typography variant="body1">Select an item to see its properties</Typography>
            )}
          </Paper>
          <Button variant="contained" color="primary" onClick={addRandomItems} sx={{ mt: 2 }}>Add Random Items</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ContainerViewer;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, TextField, Button, Checkbox, FormControlLabel, Link, MenuItem, Container, Fade, Grid, Alert } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, Timestamp, getDocs, collection, getDoc, updateDoc, arrayUnion  } from 'firebase/firestore';

const steps = [
  'Choose Location',
  'Create Account',
];

const Registration = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [selectedHall, setSelectedHall] = useState('');
  const [selectedEntryway, setSelectedEntryway] = useState('');
  const [universities, setUniversities] = useState([]);
  const [halls, setHalls] = useState([]);
  const [entryways, setEntryways] = useState([]);
  const [selectedContainerLocationId, setSelectedContainerLocationId] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchUniversities = async () => {
      const universitiesCollection = collection(db, 'universities');
      const universitySnapshot = await getDocs(universitiesCollection);
      const universityList = universitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUniversities(universityList);
    };

    fetchUniversities();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setError('');
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError('');
  };

  const handleUniversityChange = async (event) => {
    setSelectedUniversity(event.target.value);
    setSelectedHall('');
    setSelectedEntryway('');

    const hallsCollection = collection(db, `universities/${event.target.value}/containerLocations`);
    const hallsSnapshot = await getDocs(hallsCollection);
    const hallList = hallsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log("Hall list: ", hallList);
    setHalls(hallList);
  };

  const handleHallChange = (event) => {
    setSelectedHall(event.target.value);
    const hall = halls.find(h => h.name === event.target.value);
    console.log("Location iD: ", hall.id);
    setEntryways(hall.servicedEntryways);
    setSelectedEntryway('');
    setSelectedContainerLocationId(hall.id); // Save container location ID
  };

  const handleEntrywayChange = (event) => {
    setSelectedEntryway(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { firstName, lastName, email, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (password.length < 8) {
      setError("Password must contain at least 8 characters");
      return;
    }

    if (firstName.length < 2 || lastName.length < 2) {
      setError("First and last name must contain at least 2 characters");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const registrationDate = Timestamp.now();

      const userData = {
        firstName,
        lastName,
        university: selectedUniversity,
        role: 'Student',
        email,
        registrationDate,
        status: 'Active',
        payingCustomer: false,
        registrationPhase: "Unpaid",
        selectedHall,
        selectedEntryway,
        assignedContainerLocationId: selectedContainerLocationId, 
      };

      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        ...userData,
      });

      // Fetch container location document
      const containerLocationDoc = await getDoc(doc(db, `universities/${selectedUniversity}/containerLocations/${selectedContainerLocationId}`));
      const containerLocationData = containerLocationDoc.data();
      console.log("Selected container location: ", containerLocationData)
      const containerIDs = containerLocationData.containerIDs;
      const averageCustomerUtilization = 26.5; // Average space a customer uses

      let assignedContainer;

      if (!containerIDs) {
        // If no container IDs exist, create the first container for this location
        const newContainerRef = doc(collection(db, "containers"));
        assignedContainer = {
          id: newContainerRef.id,
          universityId: selectedUniversity,
          containerLocationId: selectedContainerLocationId,
          spaceReserved: 0,
          spaceCapacity: 1000,
          creationOrder: 0,
        };
        await setDoc(newContainerRef, assignedContainer);
        // Update the container location with the new container ID
        await updateDoc(doc(db, `universities/${selectedUniversity}/containerLocations/${selectedContainerLocationId}`), {
          containerIDs: arrayUnion(newContainerRef.id)
        });
      } else {
        // Find a container for the given containerLocation
        const containersCollection = collection(db, "containers");
        const containersSnapshot = await getDocs(containersCollection);
        const containersList = containersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        assignedContainer = containersList.find(container => 
          container.containerLocationId === selectedContainerLocationId && 
          containerIDs.includes(container.id)
        );
      }

      // Check if there is space in the container
      const remainingSpace = assignedContainer.spaceCapacity - assignedContainer.spaceReserved;
      if (remainingSpace < averageCustomerUtilization) {
        setError("No available space in the container for your selected hall and entryway.");
        return;
      }

      // Update container space utilization
      const updatedSpaceReserved = assignedContainer.spaceReserved + averageCustomerUtilization;
      await setDoc(doc(db, "containers", assignedContainer.id), {
        ...assignedContainer,
        spaceReserved: updatedSpaceReserved
      });

      // Add user to the container's customers collection
      const customerData = {
        actualSpace: 0,
        paidSpace: 0,
        registeredSpace: averageCustomerUtilization
      };
      await setDoc(doc(db, `containers/${assignedContainer.id}/customers`, user.uid), customerData);

      // Save user data to Firestore
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        ...userData,
        assignedContainer: assignedContainer.id
      });

      navigate('/dashboard');
    } catch (error) {
      console.error("Error creating user: ", error);
      if (error.code === 'auth/email-already-in-use') {
        setError("Email is already in use");
      } else if (error.code === 'auth/invalid-email') {
        setError("Invalid email address");
      } else {
        setError("Error creating user: " + error.message);
      }
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mx: 3 }}>
            <Typography variant="h5" gutterBottom>
              Choose Location
            </Typography>
            <TextField
              select
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="university"
              label="Select University"
              value={selectedUniversity}
              onChange={handleUniversityChange}
            >
              {universities.map((university) => (
                <MenuItem key={university.id} value={university.id}>
                  {university.name}
                </MenuItem>
              ))}
            </TextField>
            {selectedUniversity && (
              <TextField
                select
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="residentialHall"
                label="Select Residential Hall"
                value={selectedHall}
                onChange={handleHallChange}
              >
                {halls.map((hall) => (
                  <MenuItem key={hall.id} value={hall.name}>
                    {hall.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {selectedHall && (
              <TextField
                select
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="entryway"
                label="Select Entryway"
                value={selectedEntryway}
                onChange={handleEntrywayChange}
              >
                {entryways.map((entryway) => (
                  <MenuItem key={entryway} value={entryway}>
                    {entryway}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <Button
              onClick={handleNext}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={!selectedEntryway}
            >
              Continue
            </Button>
            <Typography variant="body2" align="center">
              Already registered? <Link href="/login">Sign in here</Link>.
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ maxWidth: 400, mx: 'auto' }}>
            <Typography variant="h5" gutterBottom>
              Create an account
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="fname"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="University Email"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Keep me signed in"
            />
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Create Account
            </Button>
            <Button
              onClick={handleBack}
              fullWidth
              variant="outlined"
              color="primary"
              sx={{ mb: 2 }}
            >
              Back
            </Button>
            <Typography variant="body2" align="center">
              Already registered? <Link href="/login">Sign in here</Link>.
            </Typography>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundImage: 'url(/images/background_pattern.webp)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <NavBar />
      <Container
        maxWidth="sm"
        sx={{
          mt: 4,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography fontFamily="Work Sans" fontWeight="500" variant="h4" gutterBottom>
          Registration
        </Typography>
        <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Fade in key={activeStep}>
          <Box
            sx={{
              width: '100%',
              p: 3,
              border: 2,
              borderColor: 'lightgray',
              borderRadius: 2,
              mt: 2,
              backgroundColor: 'white',
            }}
          >
            {renderStepContent(activeStep)}
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

export default Registration;
